import React, {useEffect, useState } from 'react';
import {BounceLoader, PulseLoader} from "react-spinners";


export const RequestLoader = ({ children }) => {

    useEffect(() => {

    }, []);

    return (
        <div className="sweet-loading">
            <PulseLoader
                color={`#329B8A`}
                loading={true}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
};
