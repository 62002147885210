import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../_services/global-context";
import Api from "../_services/api_service";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import Services from "../_services/services";
import URL from "../_services/routes";

export default function RsvpInvitations() {
    const [global, dispatch] = useContext(GlobalContext);
    const [rsvp, setRsvp] = useState({});
    const [errors, setErrors] = useState({});
    const [rsvpMembers, setRsvpMembers] = useState([]);

    const rsvp_id_qry = URL.urlQueryParams("rsvp_id");
    const [rsvpId, setRsvpId] = useState(rsvp_id_qry || "");

    // Effects
    useEffect(() => {
        handleLoadRsvps();
    }, []);

    // Load Rsvp
    function handleLoadRsvps() {
        const qryParams = `?pcl_id=${global.pcl.id}&rsvp_id=${rsvpId}`;
        Api.get(`client/rsvps` + qryParams, (res) => {
            const data = res.data.data;
            console.log("RSVP:", data)
            const members = [];
            data.members.map((member) => {
                if (member.status !== 1) {
                    member["accepted"] = member.status === 2 || member.status === null;
                } else {
                    member["accepted"] = true; // Pre-select "Attending" by default
                }
                members.push(member);
            });
            setRsvp(data);
            setRsvpMembers([...members]);
        });
    }

    const handleRefreshRsvp = (e) => {
        e.preventDefault();
        handleLoadRsvps();
    };

    const handleErrors = (errors, message) => {
        setErrors(errors);
        dispatch({ type: "SHOW-ERROR", payload: message });
    };

    const handleMemberChange = (member_id, val) => {
        const index = rsvpMembers.findIndex((obj) => obj.id === member_id);
        if (index !== -1) {
            rsvpMembers[index]["accepted"] = val;
            setRsvpMembers([...rsvpMembers]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        rsvp["pcl_id"] = global.pcl.id;
        rsvp["members"] = rsvpMembers;
        Api.put(
            `client/rsvps/${rsvp.id}/response`,
            rsvp,
            (res) => {
                dispatch({
                    type: "SHOW-SUCCESS",
                    payload: "Thanks, your response was submitted successfully!",
                });
                Services.redirectTo(
                    "/invitation/response",
                    global.source,
                    `rsvp_id=${rsvp.rsvp_id}&response=1`
                );
            },
            (errors) => {
                handleErrors(errors, "Error: " + JSON.stringify(errors));
            }
        );
    };

    return (
        <div style={{ background: "#f5f5f5", minHeight: "100vh", padding: "2rem 0" }}>
            <Container>
                <Card
                    className="shadow p-4"
                    style={{
                        maxWidth: "900px",
                        margin: "0 auto",
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                    }}
                >
                    <Card.Body>
                        {/* Event Name Section */}
                        <div style={{ textAlign: "center", marginBottom: "1.5rem" }}>
                            <h2 style={{ fontWeight: "bold", color: "#333" }}>Event Name: {rsvp.event_name}</h2>
                            <p className="text-muted">You're invited to this special event!</p>
                        </div>

                        <div style={{ textAlign: "center", marginBottom: "2rem" }}>
                            <p className="text-muted mb-3">
                                Please let us know if you'll be attending for each family member.
                            </p>
                            {/* Booking Details Section */}
                            <div
                                style={{
                                    border: "1px solid #ddd",
                                    borderRadius: "8px",
                                    padding: "1rem",
                                    backgroundColor: "#f9f9f9",
                                    fontSize: "1.1rem",
                                }}
                                className="mb-3"
                            >
                                <p className="mb-1" style={{ fontWeight: "bold", color: "#555" }}>
                                    Booking Details
                                </p>
                                <p className="mb-1">
                                    <strong>Date:</strong> <span style={{ color: "#777" }}>{rsvp.booking_date}</span>
                                </p>
                                <p className="mb-1">
                                    <strong>Time:</strong> <span style={{ color: "#777" }}>{rsvp.from_time} - {rsvp.to_time}</span>
                                </p>
                                <p className="mb-0">
                                    <strong>Location:</strong>{" "}
                                    <span style={{ color: "#777" }}>{rsvp.location}</span>
                                </p>
                            </div>
                            {/* Invitation From Section */}
                            <div style={{ marginBottom: "2rem" }}>
                                <p
                                    style={{
                                        fontStyle: "italic",
                                        fontSize: "1.2rem",
                                        color: "#555",
                                    }}
                                >
                                    <strong>Invitation from:</strong>{" "}
                                    <span style={{ color: "#777" }}>{rsvp.host_name}</span>
                                </p>
                            </div>
                        </div>

                        <Form autoComplete="off" onSubmit={handleSubmit}>
                            <Row className="mt-4">
                                {rsvp.members &&
                                    rsvpMembers.map((member, index) => {
                                        return (
                                            <Col sm={12} key={`inv-${index}`} className="mb-4">
                                                <div
                                                    className="d-flex align-items-center p-3"
                                                    style={{
                                                        border: "1px solid #ddd",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#fafafa",
                                                    }}
                                                >
                                                    <div className="fw-bold fs-5 text-dark" style={{ flex: 1 }}>
                                                        {`${member.first_name} ${member.last_name}`}{" "}
                                                        ({member.admission_name})
                                                        {member.accepted === true && (
                                                            <span
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    color: "green",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                ✔️
                                                            </span>
                                                        )}
                                                        {member.accepted === false && (
                                                            <span
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    color: "red",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                ❌
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="form-check form-check-inline"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginRight: "20px",
                                                        }}
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={`attending-${index}`}
                                                            name={`guest-${member.id}`}
                                                            checked={member.accepted === true}
                                                            onChange={() =>
                                                                handleMemberChange(member.id, true)
                                                            }
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                accentColor: "#007bff",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`attending-${index}`}
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: "#333",
                                                            }}
                                                        >
                                                            Attending
                                                        </label>
                                                    </div>
                                                    <div
                                                        className="form-check form-check-inline"
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={`not-attending-${index}`}
                                                            name={`guest-${member.id}`}
                                                            checked={member.accepted === false}
                                                            onChange={() =>
                                                                handleMemberChange(member.id, false)
                                                            }
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                accentColor: "#dc3545",
                                                                marginRight: "10px",
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`not-attending-${index}`}
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: "#333",
                                                            }}
                                                        >
                                                            Not Attending
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                            </Row>
                            <div className="d-flex justify-content-between mt-4">
                                <Button variant="primary" type="submit" className="me-2">
                                    Submit Response
                                </Button>
                                <Button variant="outline-secondary" onClick={handleRefreshRsvp}>
                                    Refresh
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                <div
                    style={{
                        marginTop: "2rem",
                        textAlign: "center",
                        color: "#555",
                        fontSize: "1.1rem",
                    }}
                >
                    <p>
                        Thank you for your response! Feel free to contact us if you have any
                        questions.
                    </p>
                </div>
            </Container>
        </div>
    );
}
