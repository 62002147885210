import React, {useState, useEffect, useContext} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import Api from "./_services/api_service";
import {GlobalContext} from "./_services/global-context";
import Services from "./_services/services";
import Select from "react-select";

const ShippingAddress = () => {
    const [global, dispatch] = useContext(GlobalContext);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState({});
    const [error_message, setErrorMessage] = useState('');

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState({})
    const [selectedCountry, setSelectedCountry] = useState({})
    const [shippingSelectedState, setShippingSelectedState] = useState({})
    const [shippingSelectedCountry, setShippingSelectedCountry] = useState({})

    const [isShippingAddress, setIsShippingAddress] = useState(false)


    const user_default = {id: '', country_id: '', state_id: '', city: '', postal_code: '',
        address1: '', address2: '', shipping_address_same_as_billing: true
    }
    const [user, setUser] = useState(user_default);

    useEffect(() => {
        // check if user not signin
        if (!global.token || global.token.trim() === '' || !global.user || !global.user.id) {
            window.location.assign('/login?rd=/shipping-address')
        }

        loadCountries()

        if (global.order_id) {
            handleLoadCartData()
        }

    }, []);

    useEffect(() => {
        if (countries.length > 0) {
            handleLoadShippingAddress()
        }

    }, [countries]);

    const handleLoadCartData = () => {
        const params = {
            pcl_id: global.pcl.id,
        }

        const qryParams = new URLSearchParams(params).toString()
        Api.get(`client/orders/${global.order_id}?` + qryParams, (res) => {
            const data = res.data.data
            data.cart_details.map(item => {
                if (item.shippable_item) {
                    setIsShippingAddress(true);
                    return false
                }
            })
        }, (errors) => {
            //dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Get all countries list
    const loadCountries = () => {
        Api.get(`admin/countries?pcl_id=${global.pcl.id}`, res => {
            const countries_arr = res.data.data;
            let options = []
            countries_arr.forEach(country => {
                let option = {value: country.id, label: country.name}
                options.push(option)
            })
            setCountries([...options]);
        }, error => {
            dispatch({type: "SHOW-ERROR", payload: error.message});
        });
    }

    // Load states/provinces list on country select
    function handleCountryChange(f_name, country_id, state_id = null, user_data = {}) {
        if (f_name === 'state_id') {
            setSelectedState({})
        }else {
            setShippingSelectedState({})
        }
        console.log('user_data: ', user_data)

        Api.get(`admin/states/${country_id}?pcl_id=${global.pcl.id}`, res => {
            const states_arr = res.data.data;
            let options = []
            states_arr.forEach(state => {
                let option = {value: state.id, label: state.name}

                if (state_id && state_id == state.id) {
                    console.log('f_name', f_name)
                    f_name === 'state_id' ? setSelectedState({ ...option}) : setShippingSelectedState({ ...option});
                    if (user_data.id && user_data.id != ''){
                        user_data[f_name] = state.id
                        setUser({...user_data})
                    }else {
                        user[f_name] = state.id
                        setUser({...user})
                    }
                }

                options.push(option)
            })

            setStates([...options]);
        }, error => {
            dispatch({type: "SHOW-ERROR", payload: error.message});
        });
    }

    const handleChange = (name, val) => {
        if (name === 'country_id' || name === 'shipping_country_id') {
            name === 'country_id' ? setSelectedCountry({ ...val}) : setShippingSelectedCountry({ ...val});
            val = val.value

            const state_name = (name === 'country_id') ? 'state_id' : 'shipping_state_id'
            handleCountryChange(state_name, val)
        }

        if (name === 'state_id' || name === 'shipping_state_id') {
            name === 'state_id' ? setSelectedState({ ...val}) : setShippingSelectedState({ ...val});
            val = val.value
        }

        user[name] = val;
        setUser({...user});
    }

    const handleLoadShippingAddress = () => {
        console.log('handleLoadShippingAddress: ')
        const params = `?pcl_id=${global.pcl.id}`
        Api.get(`client/users/${global.user.id}` + params, (res) => {
            const data = res.data.data;
            const country_id = data.country_id || global.location.country_id
            console.log('country_id', country_id)
            setUser({...data})

            // Billing address
            for (let country of countries) {
                if (country.value === country_id) {
                    setSelectedCountry({...country})
                    data['country_id'] = country_id;

                    const state_id = data.state_id || global.location.state_id
                    handleCountryChange('state_id', country.value, state_id, data)
                    break;
                }
            }

            // Shipping address
            const s_country_id = data.shipping_country_id;
            for (let country of countries) {
                if ((s_country_id && s_country_id == country.value) || country.value === country_id) {
                    setShippingSelectedCountry({...country})
                    data['shipping_country_id'] = s_country_id || country_id;

                    const state_id = data.shipping_state_id || global.location.state_id
                    handleCountryChange('shipping_state_id', country.value, state_id, data)
                    break;
                }
            }



        }, (error) => {
            console.log('error: ', error)
            setErrors(error);
            setErrorMessage(Services.getErrorList(error))
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)})
        });
    }

    const handleSaveShippingAddress = (e) => {
        e.preventDefault();
        user['pcl_id'] = global.pcl.id
        setProcessing(true)

        Api.put(`client/users/${user.id}`, user, (res) => {
            const data = res.data.data;
            dispatch({type: 'SHIPPING-ADDRESS', payload: true})
            dispatch({type: "SHOW-SUCCESS", payload: 'Shipping address saved successfully!'})

            setTimeout(() => {
                window.location.assign('/checkout');
            }, 2000)

        }, (error) => {
            setProcessing(false)
            setErrors(error);
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)})
        });
    }


    // React select style
    const targetHeight = 38
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    // console.log(global.token)
    return (
        <>
            <Container>
                <div className="auth-wrapper">
                    <Form onSubmit={handleSaveShippingAddress} autoComplete="off">

                        <div className='fw-bold text-center fs-21x mt-3'>Enter your billing address!</div>
                        {error_message !== '' && (
                            <div className="text-center fs-17x mb-3">
                                <span style={{color: 'red'}}>{error_message}</span>
                            </div>
                        )}

                        <Row className='mt-3'>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="country_id">
                                    <Form.Label className=''>Country</Form.Label>
                                    <Select styles={selectStyles}
                                            value={selectedCountry}
                                            options={countries}
                                            className={(errors.country_id) && 'is-invalid'}
                                            onChange={(e) => handleChange('country_id', e)}/>
                                    <div className="invalid-feedback">{errors.country_id}</div>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="state_id">
                                    <Form.Label className=''>State</Form.Label>
                                    <Select styles={selectStyles} placeholder='Select state'
                                            options={states} value={selectedState}
                                            className={(errors.state_id) && 'is-invalid'}
                                            onChange={(e) => handleChange('state_id', e)}/>
                                    <div className="invalid-feedback">{errors.state_id}</div>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control size='md' type="text" className={(errors.city) && 'is-invalid'}
                                                  placeholder='city'
                                                  value={user.city}
                                                  onChange={(e) => handleChange('city', e.target.value)}/>
                                    <Form.Text className="text-danger">{errors.city}</Form.Text>
                                </Form.Group>
                            </Col>


                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="postal_code">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control size='md' type="text"
                                                  className={(errors.postal_code) && 'is-invalid'}
                                                  placeholder="postal code"
                                                  value={user.postal_code}
                                                  onChange={(e) => handleChange('postal_code', e.target.value)}/>
                                    <Form.Text className="text-danger">{errors.postal_code}</Form.Text>
                                </Form.Group>
                            </Col>

                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address1">
                                    <Form.Label>Address 1 <small className='text-muted'>(Street address or P.O
                                        Box)</small></Form.Label>
                                    <Form.Control size='md' type='text'
                                                  placeholder="Street address or P.O Box"
                                                  className={(errors.address1) && 'is-invalid'}
                                                  value={user.address1}
                                                  onChange={(e) => handleChange('address1', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.address1}</div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3" controlId="address2">
                                    <Form.Label>Address 2 <small className='text-muted'>(Apt., Unit, Building,
                                        Suite)</small></Form.Label>
                                    <Form.Control size='md' type='text'
                                                  placeholder="Apt., Unit, Building, Suite"
                                                  className={(errors.address2) && 'is-invalid'}
                                                  value={user.address2}
                                                  onChange={(e) => handleChange('address2', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.address2}</div>
                                </Form.Group>
                            </Col>

                        </Row>


                        {(isShippingAddress)&&
                            <>
                                <div className='fw-bold text-center fs-21x mt-3 border-bottom'>Enter your shipping address!</div>
                                <Row className='mt-4'>
                                    <Col sm={12}>
                                        <Form.Check size='sm' type="checkbox" name="shippable_item"
                                                    id="shipping_address_same_as_billing"
                                                    label="Shipping address same as billing address"
                                                    checked={user.shipping_address_same_as_billing}
                                                    onChange={(e) => handleChange('shipping_address_same_as_billing', e.target.checked)}
                                        />
                                    </Col>
                                </Row>

                                {(!user.shipping_address_same_as_billing) &&
                                    <Row className='mt-2'>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_country_id">
                                                <Form.Label className=''>Country</Form.Label>
                                                <Select styles={selectStyles} className={(errors.shipping_country_id) && 'is-invalid'}
                                                        value={shippingSelectedCountry}
                                                        options={countries}
                                                        onChange={(e) => handleChange('shipping_country_id', e)}/>
                                                <div className="invalid-feedback">{errors.shipping_country_id}</div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_state_id">
                                                <Form.Label className=''>State</Form.Label>
                                                <Select styles={selectStyles} className={(errors.shipping_state_id) && 'is-invalid'}
                                                        value={shippingSelectedState}
                                                        options={states}
                                                        onChange={(e) => handleChange('shipping_state_id', e)}/>
                                                <div className="invalid-feedback">{errors.shipping_state_id}</div>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control size='md' type="text"
                                                              className={(errors.shipping_city) && 'is-invalid'}
                                                              placeholder='city'
                                                              value={user.shipping_city}
                                                              onChange={(e) => handleChange('shipping_city', e.target.value)}/>
                                                <Form.Text className="text-danger">{errors.shipping_city}</Form.Text>
                                            </Form.Group>
                                        </Col>


                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_postal_code">
                                                <Form.Label>Postal Code</Form.Label>
                                                <Form.Control size='md' type="text"
                                                              className={(errors.shipping_postal_code) && 'is-invalid'}
                                                              placeholder="postal code"
                                                              value={user.shipping_postal_code}
                                                              onChange={(e) => handleChange('shipping_postal_code', e.target.value)}/>
                                                <Form.Text
                                                    className="text-danger">{errors.shipping_postal_code}</Form.Text>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_address1">
                                                <Form.Label>Address 1 <small className='text-muted'>(Street address or
                                                    P.O
                                                    Box)</small></Form.Label>
                                                <Form.Control size='md' type='text'
                                                              placeholder="Street address or P.O Box"
                                                              className={(errors.shipping_address1) && 'is-invalid'}
                                                              value={user.shipping_address1}
                                                              onChange={(e) => handleChange('shipping_address1', e.target.value)}/>
                                                <div className="invalid-feedback">{errors.shipping_address1}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="shipping_address2">
                                                <Form.Label>Address 2 <small className='text-muted'>(Apt., Unit,
                                                    Building,
                                                    Suite)</small></Form.Label>
                                                <Form.Control size='md' type='text'
                                                              placeholder="Apt., Unit, Building, Suite"
                                                              className={(errors.shipping_address2) && 'is-invalid'}
                                                              value={user.shipping_address2}
                                                              onChange={(e) => handleChange('shipping_address2', e.target.value)}/>
                                                <div className="invalid-feedback">{errors.shipping_address2}</div>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                }
                            </>
                        }

                        {/* Shipping address buttons */}
                        <div className="form-group text-end">
                            {/* Cancel button */}

                            <button type="submit" className="btn btn-success me-2">
                                Save & Proceed
                                {processing && <span>&nbsp;...</span>}
                            </button>

                            <Button size='md' type="button" variant="warning"
                                    onClick={() => {
                                        Services.logoutAndRedirect('/cart', global.source)
                                    }}>Cancel</Button>
                        </div>

                    </Form>
                    <br/>
                </div>
            </Container>
        </>
    );
}

export default ShippingAddress;
