import React, {useContext, useEffect, useState} from "react";
import URLr from "../_services/routes";
import Api from "../_services/api_service";
import axios from "axios";
import {GlobalContext} from "../_services/global-context";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import Services from "../_services/services";
import {CalendarMinus, PencilSquare, Plus, XCircleFill} from "react-bootstrap-icons";
import {cloneDeep} from "lodash";
import room_default_img from "../../images/product-dummy-image-1.jpg";

import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";


export default function CartItemProperties() {
    const [global, dispatch] = useContext(GlobalContext);
    const [focused, setFocused] = useState(false);
    const [selectedDate, setSelectedDate] = useState();

    const [item, setItem] = useState({})
    const [itemId, setItemId] = useState(null)
    const [categoryId, setCategoryId] = useState(null)
    const [quantity, setQuantity] = useState(null)

    const [orderId, setOrderId] = useState(global.order_id || null)
    const [properties, setProperties] = useState([])
    const [bookings, setBookings] = useState([])
    const [orderDetailId, setOerDetailId] = useState(null);
    const [bookingsData, setBookingsData] = useState([]);
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const [slotsData, setSlotsData] = useState({});
    const [bookableProperties, setBookableProperties] = useState([]);
    const [preSelectedBooking, setPreSelectedBooking] = useState({});

    const [admissions, setAdmissions] = useState([])
    const [propertyChange, setPropertyChange] = useState(false)

    const [errors, setErrors] = useState({});
    const [errorsArr, setErrorsArr] = useState([]);
    const [bookingErrors, setBookingErrors] = useState([]);
    const [response, setResponse] = useState([])

    const [selectedSlots, setSelectedSlots] = useState([])
    const [slotSearchTerms, setSlotSearchTerms] = useState({pcl_id: global.pcl.id})

    const b_details_default = {
        max_bookings: 0,
        max_bookings_per_day: 1,
        max_checkins: 1,
        max_bookings_required: 0,
        slots_auto_selection: 0
    }
    const [bookingDetails, setBookingDetails] = useState(b_details_default)
    const [availableDates, setAvailableDates] = useState([])

    const item_id = URLr.urlQueryParams('item_id');
    const category_id = URLr.urlQueryParams('category_id');
    const order_detail_id = URLr.urlQueryParams('order_detail_id');
    const req_quantity = URLr.urlQueryParams('quantity');

    // Effects
    useEffect(() => {
        if (req_quantity) {
            setQuantity(req_quantity)
        }

        if (category_id) {
            setCategoryId(category_id)
        }

        if (item_id && item_id !== undefined && item_id !== '') {
            setItemId(item_id)
            handleGetItemDetails(item_id)
            handleGetAdmissions(item_id)

            handleCheckAvailability()
        }

        if (order_detail_id) {
            setOerDetailId(order_detail_id)
            handleCartEditDetails(item_id, order_detail_id)
        } else {
            handleGetProperties(item_id)
        }
    }, [])

    useEffect(() => {
        if (orderDetailId && !global.order_id) {
            dispatch({type: "SHOW-ERROR", payload: 'Sorry your order has been expired.'});
            setTimeout(() => {
                window.location.assign('/');
            }, 3000);
        }
    }, [global.order_id])

    useEffect(() => {
        if (orderDetailId) {
            if (bookings.length === 0 && bookableProperties.length > 0 && admissions.length > 0) {
                let bookings_arr = handleArrangeBookingData(bookableProperties, admissions, bookingsData)
                console.log('bookings_arr: ', bookings_arr)
                if (bookings_arr.length >= 1 && bookings_arr[0].id && bookings_arr[0].booking_date != '') {
                    bookings_arr = handleSetBookingSlots(bookings_arr)
                } else {
                    setBookings([...bookings_arr])
                }
            }
        } else {
            if (bookings.length === 0 && bookableProperties.length > 0 && admissions.length > 0) {
                handleSetBooking(false)
            }
        }

    }, [bookableProperties, admissions, bookingsData])


    const handleGetItemDetails = (item_id) => {
        const params = "name,event_id,max_bookings,max_bookings_per_day,max_bookings_required,max_checkins,advance_booking_days,slots_auto_selection,booking_confirmations,description_short";
        let qryParams = {
            pcl_id: global.pcl.id,
            params: params,
        }

        const qParams = '?' + new URLSearchParams(qryParams).toString();
        Api.get(`client/items/${item_id}` + qParams, (res) => {
            const data = res.data.data
            bookingDetails['max_bookings'] = data.max_bookings || 0;
            bookingDetails['max_checkins'] = data.max_checkins || 1;
            bookingDetails['max_bookings_per_day'] = data.max_bookings_per_day || 1;
            bookingDetails['max_bookings_required'] = data.max_bookings_required || 0;
            bookingDetails['slots_auto_selection'] = data.slots_auto_selection || 0;
            bookingDetails['advance_booking_days'] = data.advance_booking_days || 0;
            setItem(data)
            setBookingDetails({...bookingDetails})
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleGetAdmissions = (item_id) => {
        let qryParams = `?pcl_id=${global.pcl.id}&item_id=${item_id}`;

        Api.get(`client/item-admissions` + qryParams, (res) => {
            const data = res.data.data
            //console.log('admissions', data)
            setAdmissions([...data])
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleGetProperties = (item_id) => {
        let qryParams = `?pcl_id=${global.pcl.id}&item_id=${item_id}`;

        Api.get(`client/item-properties` + qryParams, (res) => {
            const data = res.data.data
            if (data.length > 0) {

                // set bookable properties
                const bookable_properties = handleSetBookableProperties(data)
                setProperties(data)
            } else {
                handleAddToCart(undefined, item_id)
            }
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    // Get cart update properties
    const handleCartEditDetails = (item_id, order_detail_id) => {
        // e.preventDefault();
        const params = '?pcl_id=' + global.pcl.id + '&item_id=' + item_id
            + '&order_id=' + orderId || '';
        Api.get(`client/item-properties/${order_detail_id}` + params, (res) => {
            const data = res.data.data

            const bookings_data = data.bookings
            setBookingsData([...bookings_data])
            const properties_arr = handleSetPropertiesOrder(data.properties);
            setProperties(properties_arr)
            const bProperties = handleSetBookableProperties(properties_arr)

            const from_date = data.properties.find(obj => obj.slug == 'from_date')
            const max_bookings = data.properties.find(obj => obj.slug == 'max-bookings')
            const adm_availability = data.properties.find(obj => obj.slug == 'admissions-availability')
            if (max_bookings && max_bookings.cart_value) {
                bookingDetails['max_bookings'] = max_bookings.cart_value.property_value || max_bookings.cart_value
            }

            if (adm_availability && adm_availability.cart_value) {
                if (adm_availability && adm_availability.value && adm_availability.property_values) {
                    const property_value = adm_availability.property_values.find(obj => obj.id == adm_availability.value)
                    if (property_value) {
                        let avail_data = property_value.value
                        avail_data = avail_data.split(',');

                        let error = false;
                        console.log('avail_data: ', avail_data)
                        if (avail_data.length == 2 || avail_data.length == 4) {
                            const avail_weeks = avail_data.map((week) => week.trim());
                            avail_weeks.map((week, index) => {
                                error = false
                                if (index == 2 || index == 3) {
                                    week = avail_weeks[0] + ' ' + week
                                }

                                const parsedDateTime = moment(week, undefined, true);
                                const valid = parsedDateTime.isValid();
                                if (!valid) {
                                    error = true;
                                }
                            })

                            if (!error) {
                                if (avail_data[0] && avail_data[1]) {
                                    bookingDetails['from_date'] = avail_data[0]
                                    bookingDetails['to_date'] = avail_data[1]
                                }

                                if (avail_data[2] && avail_data[3]) {
                                    bookingDetails['from_time'] = avail_data[2]
                                    bookingDetails['to_time'] = avail_data[3]
                                }

                                // console.log(bookingDetails)
                                // setBookingDetails({...bookingDetails})
                            }
                        }
                    }
                }
            }

            setBookingDetails({...bookingDetails})

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Check availability of dates for to show in calendar
    const handleCheckAvailability = (selected_date = null) => {
        let qryParams = {
            pcl_id: global.pcl.id,
            item_id: item_id,
            selected_date: (selected_date) ? moment(selected_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        }

        if (bookingDetails && bookingDetails.from_date) {
            qryParams['from_date'] = bookingDetails.from_date

        }

        if (bookingDetails && bookingDetails.to_date) {
            qryParams['to_date'] = bookingDetails.to_date
        }


        const qParams = '?' + new URLSearchParams(qryParams).toString();
        Api.get(`client/availabilities` + qParams, (res) => {
            const data = res.data.data
            setAvailableDates([...data])
        }, (errors) => {
            // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    // Check is valid bookings
    const isValidBooking = (booking) => {
        let error = null;
        const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
        if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
            error = 'Select valid booking date';
        }

        error = (error || !booking.bookings || booking.bookings.length === 0);
        for (const subBooking of booking.bookings) {
            if (!subBooking.bookable_service_id) {
                error = 'Bookable service ID is missing';
            }
            if (!subBooking.from_time) {
                error = 'From time is missing';
            }
            if (!subBooking.to_time) {
                error = 'To time is missing';
            }
        }

        return (!error);
    }

    // Count valid bookings count
    const validateBookingsCount = (bookings_arr) => {
        let validCount = 0;
        for (const booking of bookings_arr) {
            let error = null;
            const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
            if (!booking.booking_date || booking.booking_date.trim() === '' || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
                error = 'Add valid booking date';
            }

            for (const subBooking of booking.bookings) {
                if (!subBooking.bookable_service_id) {
                    error = 'Bookable service ID is missing';
                }
                if (!subBooking.from_time) {
                    error = 'From time is missing';
                }
                if (!subBooking.to_time) {
                    error = 'To time is missing';
                }
            }

            if (!error) validCount++;
        }

        return validCount;
    }

    const handleBookStatusChange = (bIndex, value) => {
        bookings[bIndex]['status'] = value
        bookings[bIndex]['change'] = true
        setBookings([...bookings])
    }

    const handleImageChange = (e, index) => {
        properties[index]['image_url'] = URL.createObjectURL(e.target.files[0])
        properties[index]['value'] = e.target.files[0]
        setProperties([...properties])
    }

    // Arrange booking date
    const handleArrangeBookingData = (iProperties, admissions_arr, bookings_data = []) => {
        // console.log('handleSetBooking')

        let res_bookings = []
        if (bookings_data.length === 0) {
            const bProperties = [];
            iProperties.map((p) => {
                if (p.property_type == 'bookable-services') {
                    let row = {
                        property_id: p.id, name: p.name, bookable_service_id: null,
                        booking_date: '', from_time: null, to_time: null,
                        status: 1, change: false
                    }

                    bProperties.push(row)
                }
            });

            const bRow = {
                booking_date: '',
                admissions: admissions_arr,
                bookings: bProperties,
                slots: [],
                status: 1,
                selected: true
            }
            res_bookings = [bRow]
        } else {
            let bookings_copy = [];

            const bookings_arr = [];
            bookings_data.map((booking) => {
                // make bookable rows to show in table
                const bFound = bookings_arr.findIndex(obj => obj.id == booking.booking_id)
                if (bFound == -1) {
                    const bRow = {
                        id: booking.booking_id,
                        booking_date: booking.booking_date,
                        slots: [],
                        bookings: [],
                        admissions: admissions_arr,
                        status: booking.status,
                    }

                    bookings_arr.push(bRow)
                }

                // arrange booking data
                const fIndex = bookings_copy.findIndex(obj => obj.booking_id == booking.booking_id &&
                    obj.property_id == booking.property_id)

                if (fIndex == -1) {
                    bookings_copy.push(booking)
                }
            })

            iProperties.map((property, idx) => {
                const property_id = property.id
                bookings_arr.map((booking, index) => {
                    const fBooking = bookings_copy.find(obj => obj.booking_id == booking.id && obj.property_id == property_id)

                    const rBookings = bookings_arr[index]['bookings']
                    const fIndex = rBookings.findIndex(obj => obj.property_id == property_id)
                    if (fBooking && fIndex == -1) {
                        fBooking['name'] = property.name
                        fBooking['from_time'] = moment(fBooking['from_time'], 'HH:mm:ss').format('HH:mm')
                        fBooking['to_time'] = moment(fBooking['to_time'], 'HH:mm:ss').format('HH:mm')
                        rBookings.push(fBooking)
                        bookings_arr[index]['bookings'] = rBookings;
                    }
                })
            })

            // Set booking admissions
            bookings_arr.map((booking, index) => {
                const admissions_data = booking.admissions;

                const admissions_list = admissions_data.map((admission, idx) => {
                    const fBooking = bookings_data.find(obj => obj.booking_id == booking.id && obj.admission_id == admission.id);
                    if (fBooking) {
                        // Create a new admission object with updated requested_persons value
                        const updatedAdmission = {...admission, requested_persons: fBooking.expected_guests};
                        return updatedAdmission;
                    }
                });
                bookings_arr[index]['admissions'] = admissions_list;
            });

            res_bookings = bookings_arr
        }

        const sort_bookings = handleSortBookings(res_bookings)
        return sort_bookings;
    }

    const handleSetBookingSlots = async (bookings_arr) => {
        let res_bookings = []
        for (const [index, booking] of bookings_arr.entries()) {
            const b_bookings = booking.bookings;

            for (const [b_index, b_booking] of b_bookings.entries()) {
                const params = {
                    pcl_id: global.pcl.id,
                    booking_id: b_booking.booking_id || '',
                    property_id: b_booking.property_id,
                    bookable_service_id: b_booking.bookable_service_id,
                    booking_date: booking.booking_date
                }

                let slot_found = false;
                let apiUrl = Api.getApiURL();
                const qParams = "?" + new URLSearchParams(params).toString();

                // Request to get spaces slots
                const res = await axios.get(apiUrl + "client/bookable-service-times" + qParams);
                const sData = res.data.data;
                sData['property_id'] = b_booking.property_id;
                console.log('sData: ', sData)
                const b_slots = bookings_arr[index].slots || []

                const bsIndex = b_slots.findIndex(obj => obj.property_id == b_booking.property_id)
                if (bsIndex >= 0) {
                    b_slots[bsIndex] = sData
                } else {
                    b_slots.push(sData);
                }

                bookings_arr[index]['slots'] = b_slots;
            }
        }

        setBookings([...bookings_arr])
    }

    const handleSetPropertiesOrder = (properties_arr) => {
        // Sort in ascending order by sort_id and id
        properties_arr.sort((a, b) => {
            // If sort_order is equal, sort by id
            if (a.sort_order === b.sort_order) {
                return a.id - b.id;
            }
            // Otherwise, sort by sort_order
            return a.sort_order - b.sort_order;
        });
        return properties_arr
    }

    const handleSortBookings = (s_bookings = null) => {
        s_bookings = s_bookings || cloneDeep(bookings);

        s_bookings.sort((a, b) => {
            const a_booking = a.bookings[0] || null;
            const b_booking = b.bookings[0] || null;

            // First, compare by booking_date
            if (a_booking.booking_date > b_booking.booking_date) {
                return -1;
            } else if (a_booking.booking_date < b_booking.booking_date) {
                return 1;
            } else {
                // If booking_date is the same, compare by from_time
                if (a_booking.from_time > b_booking.from_time) {
                    return -1;
                } else if (a_booking.from_time < b_booking.from_time) {
                    return 1;
                } else {
                    return 0; // If both booking_date and from_time are equal
                }
            }
        });

        return s_bookings;
    }

    const handleSetBookableProperties = (properties_list) => {
        const iProperties = (properties_list) ? properties_list : properties;
        const bookableServices = iProperties.filter(obj => obj.property_type === "bookable-services");
        if (bookableServices.length > 0) {
            // sort_order in ascending order by sort_id and id
            bookableServices.sort((a, b) => {
                if (a.sort_order === b.sort_order) {
                    return a.id - b.id; // If sort_order is equal, sort by id
                }
                return a.sort_order - b.sort_order; // Otherwise, sort by sort_order
            });

            setBookableProperties(bookableServices)
            return bookableServices;
        }
    }

    const handleCheckMaxBookingsPerDayAllow = (booking_date, bIndex = null) => {
        // max_checkins
        let count = 0;
        if (bookingDetails.max_bookings_per_day && bookingDetails.max_bookings_per_day >= 1) {
            const bookings_data = [...bookings]
            bookings_data.forEach((booking, index) => {
                const bk = booking.bookings[0];
                if (bk && booking.booking_date == booking_date && index != bIndex) {
                    count++;
                }
            });
        }

        const mBookings_day = bookingDetails.max_bookings_per_day;
        return ((mBookings_day && mBookings_day == 0) || mBookings_day > count) ? true : false;
    }

    const handleCheckMaxCheckins = (bookings_arr = null, type = 'booking') => {
        // max_checkins
        let checkin = true;
        const max_checkins = {};
        if (bookingDetails.max_checkins && bookingDetails.max_checkins >= 1) {
            const bookings_data = bookings_arr || bookings
            bookings_data.forEach(booking => {
                const bAdmissions = booking.admissions;
                bAdmissions.forEach(adm => {
                    const allowed = adm.requested_persons || adm.allowed_person;
                    if (!max_checkins[adm.id]) {
                        max_checkins[adm.id] = allowed;
                    } else {
                        max_checkins[adm.id] = parseInt(max_checkins[adm.id]) + parseInt(allowed);
                    }
                });
            });

            // Loop over max_checkins
            for (const id in max_checkins) {
                if (max_checkins.hasOwnProperty(id)) {
                    const maxCount = max_checkins[id];
                    if (maxCount > bookingDetails.max_checkins) {
                        checkin = false
                        break;
                    }
                }
            }
        }

        return true;
        return (type == 'admission') ? max_checkins : checkin;
    }

    const handleSetBooking = (max_check = true) => {

        if (bookingDetails.max_bookings != 0 && bookings.filter(obj => obj.status != 5).length >= bookingDetails.max_bookings) {
            dispatch({type: "SHOW-ERROR", payload: 'Sorry, max booking limit reached.'})
            return false;
        }

        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            if (!booking.booking_date) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id) {
                    error = `"${b_booking.name}" not selected.`
                    errors.push(error)
                }

                if (!b_booking.from_time || !b_booking.to_time) {
                    error = `"${b_booking.name}" slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }

        bookings.map((booking, index) => {
            bookings[index]['selected'] = false
        });

        const bProperties = [];
        bookableProperties.map((p) => {
            let row = {
                bookable_service_id: null, from_time: null, to_time: null,
                property_id: p.id, name: p.name,
                expected_guests: 0, status: 1, change: false
            }

            bProperties.push(row)
        })

        const isAnyReqProperty = properties.find(obj => obj.slug == 'from_date' || obj.slug == 'max-bookings' || obj.slug == 'admissions-availability')

        const selected = (isAnyReqProperty) ? true : true;

        const bRow = {
            booking_date: '',
            slots: [],
            admissions: admissions,
            bookings: bProperties,
            status: 1,
            selected: selected
        }

        const mcBookings = cloneDeep(bookings)

        mcBookings.push(bRow)
        const max_checkin_res = (max_check) ? handleCheckMaxCheckins(mcBookings) : true;
        if (max_checkin_res) {
            bookings.unshift(bRow)
            setBookings([...bookings])
            setPreSelectedBooking(bRow)
        } else {
            dispatch({type: 'SHOW-ERROR', payload: 'Cannot add more bookings, Max checkin limit reached.'})
            return false;
        }

    }

    const handleValidateBooking = (b_index) => {
        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            const booking_start_date = moment().add(bookingDetails.advance_booking_days || 0, "days");
            if (!booking.booking_date || moment(booking.booking_date).isBefore(booking_start_date, 'day')) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id) {
                    error = `"${b_booking.name}" not selected.`
                    errors.push(error)
                }

                if (!b_booking.from_time || !b_booking.to_time) {
                    error = `"${b_booking.name}" slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }

        return true;
    }

    const handleRemoveBooking = (b_index) => {
        let bClone = cloneDeep(bookings)
        const booking = bClone[b_index];

        Swal.fire({
            title: 'Alert!',
            html: "<span>Are you sure to delete this booking!</span>",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b35322',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                if (booking.id) {
                    handleDeleteBookings([booking.id]).then(res => {
                        if (res) {
                            bClone.splice(b_index, 1)
                            setBookings([...bClone])
                            dispatch({type: 'SHOW-SUCCESS', payload: 'Booking removed successfully.'})
                        } else {
                            dispatch({type: 'SHOW-ERROR', payload: 'Sorry, booking not removed please try again.'})
                        }
                        return res;
                    })
                    // bClone[b_index]['status'] = 5;
                    // bClone[b_index]['selected'] = false;
                    // setBookings([...bClone])
                } else {
                    if (b_index == 0 && bookings.length === 1) {
                        booking['booking_date'] = '';
                        const b_bookings = booking.bookings
                        b_bookings.map((b_booking, b_index) => {
                            delete b_bookings[b_index].booking_id;
                            delete b_bookings[b_index].booking_date;
                            b_bookings[b_index]['booking_id'] = '';
                            b_bookings[b_index]['booking_date'] = '';
                            b_bookings[b_index]['from_time'] = '';
                            b_bookings[b_index]['to_time'] = '';
                            b_bookings[b_index]['bookable_service_id'] = '';
                        });

                        booking['selected'] = true
                        booking['bookings'] = b_bookings
                        bClone = [booking];
                    } else {
                        bClone.splice(b_index, 1)
                    }

                    setBookings([...bClone])
                    dispatch({type: 'SHOW-WARNING', payload: 'Bookings removed successfully.'})
                }
            }
        })
    }

    async function handleDeleteBookings(booking_id) {
        let apiUrl = Api.getApiURL();
        const params = {
            pcl_id: global.pcl.id,
            order_id: orderId,
            booking_id: booking_id
        }

        booking_id = (Array.isArray(booking_id) && booking_id.length > 0) ? booking_id[0] : booking_id;

        // Request to delete booking
        try {
            const res = await axios.delete(apiUrl + `client/bookings/${booking_id}`, {data: params});
            const data = res.data.data;
            console.log('Booking deleted:', data);
            return true;
        } catch (error) {
            console.error('Error deleting booking:', error);
            return false;
        }
    }

    const handleCancelBookingChanges = (b_index) => {
        const bClone = cloneDeep(bookings)
        bClone[b_index] = preSelectedBooking;
        bClone[b_index]['selected'] = false;
        setBookings([...bClone])
        setPreSelectedBooking({})
    }

    const handleSetEditBooking = (b_index) => {

        // check camp properties if exist
        const max_booking = properties.find(obj => obj.slug == 'max-bookings')
        const availability = properties.find(obj => obj.slug == 'admissions-availability')

        let max_bookings = null;
        let avail_weeks = null;

        // check and validate max_bookings value
        if (max_booking) {
            if (max_booking.value && max_booking.property_values) {
                const found = max_booking.property_values.find(obj => obj.id == max_booking.value)
                if (found) {
                    max_bookings = found.value
                    bookingDetails['max_bookings'] = max_bookings
                    setBookingDetails({...bookingDetails})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
                    return false;
                }
            } else {
                dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
                return false;
            }
        }

        let error = false;
        if (availability) {
            if (availability.value && availability.property_values) {
                const found = availability.property_values.find(obj => obj.id == availability.value)
                if (found) {
                    let avail_data = found.value
                    avail_data = avail_data.split(',');

                    if (avail_data.length == 2 || avail_data.length == 4) {
                        avail_weeks = avail_data.map((week) => week.trim());
                        avail_weeks.map((week, index) => {
                            if (index == 2 || index == 3) {
                                week = avail_weeks[0] + ' ' + week
                            }
                            const parsedDateTime = moment(week, undefined, true);
                            const valid = parsedDateTime.isValid();
                            if (!valid) {
                                error = true;
                            }
                        })

                        if (error) {
                            dispatch({
                                type: 'SHOW-ERROR',
                                payload: 'Sorry your date or time is incorrect, Please make sure you added correct date and time with correct format in ' + availability.name
                            })
                            return false;
                        }

                        if (avail_data[0] && avail_data[1]) {
                            bookingDetails['from_date'] = avail_data[0]
                            bookingDetails['to_date'] = avail_data[1]
                        }

                        if (avail_data[2] && avail_data[3]) {
                            bookingDetails['from_time'] = avail_data[2]
                            bookingDetails['to_time'] = avail_data[3]
                        }

                        // console.log(bookingDetails)
                        setBookingDetails({...bookingDetails})

                    } else {
                        error = true;
                    }

                } else {
                    error = true;
                }
            } else {
                error = true;
            }
        }

        if (error) {
            dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${availability.name}.`})
            return false;
        }

        // Check for booking from date
        let from_booking_date = null; //moment().format('YYYY-MM-DD')
        const from_date = properties.find(obj => obj.slug == 'from_date')
        if (from_date) {
            from_booking_date = (from_date.value) ? from_date.value : moment().format('YYYY-MM-DD');
            bookingDetails['booking_from_date'] = from_booking_date
            setBookingDetails({...bookingDetails})
            /*dispatch({type: 'SHOW-ERROR', payload: `Please select correct ${max_booking.name}.`})
            return false;*/
        }

        console.log(max_booking)
        console.log(from_booking_date)

        bookings[b_index]['selected'] = true;
        setBookings([...bookings])
        setPreSelectedBooking(bookings[b_index])
    }

    const handleCloseEdit = (b_index) => {
        const errors = [];
        bookings.map((booking, index) => {
            let error = '';
            if (!booking.booking_date) {
                error = `Please add booking date and other required details.`
                errors.push(error)
                setBookingErrors([...errors])
                return false;
            }

            booking.bookings.map((b_booking, b_index) => {
                if (!b_booking.bookable_service_id || !b_booking.from_time || !b_booking.to_time) {
                    error = `“${b_booking.name}” slot not selected.`
                    errors.push(error)
                }
            })
        });

        if (errors.length > 0) {
            setBookingErrors([...errors])
            return false;
        } else {
            setBookingErrors([])
        }


        if (!isValidBooking(bookings[b_index])) {
            dispatch({type: 'SHOW-WARNING', payload: 'Please select correct date and slots for booking.'})
            return false;
        }


        bookings[b_index]['selected'] = false;
        setBookings([...bookings])
    }

    const handleCancelEdit = (b_index) => {
        bookings[b_index]['selected'] = false;
        setBookings([...bookings])
    }

    const handleResetBooking = () => {

        const booking_ids = [];
        let bookings_copy = cloneDeep(bookings)
        const new_booking = bookings_copy[0] ? cloneDeep(bookings_copy[0]) : null;

        bookings_copy.map((booking, index) => {
            if (booking.id) {
                booking_ids.push(booking.id)
            } else {
                bookings_copy.splice(index, 1);
            }
        });

        if (new_booking) {
            new_booking['booking_date'] = '';
            const b_bookings = new_booking.bookings
            b_bookings.map((b_booking, b_index) => {
                delete b_bookings[b_index].booking_id;
                delete b_bookings[b_index].booking_date;
                b_bookings[b_index]['booking_id'] = '';
                b_bookings[b_index]['booking_date'] = '';
                b_bookings[b_index]['from_time'] = '';
                b_bookings[b_index]['to_time'] = '';
                b_bookings[b_index]['bookable_service_id'] = '';
            });

            delete new_booking.id;
            new_booking['selected'] = true
            new_booking['bookings'] = b_bookings
        }

        bookings_copy = [new_booking]
        console.log('new_booking: ', new_booking)
        if (booking_ids.length > 0) {
            handleDeleteBookings(booking_ids).then(res => {
                if (res) {
                    setBookings([...bookings_copy])
                    dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are removed successfully.'})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Sorry, booking not removed please try again.'})
                }
                return res;
            })
        } else {
            setBookings([...bookings_copy])
            dispatch({type: 'SHOW-SUCCESS', payload: 'All previous bookings are removed successfully.'})
        }
    }

    // handle property change
    async function handleChange(e, index, value, change_type = null) {
        // if (e) e.preventDefault();

        const property = cloneDeep(properties[index])
        const is_booking_set = bookings.find(obj => obj.id || (obj.booking_date && obj.booking_date !== ''))
        console.log('is_booking_set: ', is_booking_set)

        if (is_booking_set && (property.slug == 'from_date' || property.slug == 'max-bookings' || property.slug == 'admissions-availability')) {
            const result = await Swal.fire({
                title: 'Be Aware!',
                html: `<span>All bookings will be removed. Are you sure to change ${property.name}!</span>`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#b35322',
                confirmButtonText: 'Yes'
            });

            if (!result.isConfirmed) {
                return false;
            } else {
                handleResetBooking();
            }
        }


        properties[index]['value'] = value;
        setProperties([...properties]);
        setPropertyChange(true);

        // check and set max-bookings
        if (property.slug == 'max-bookings') {
            handlePropertyMaxBookings(property);
        } else if (property.slug == 'from_date') {
            const from_date = (property.value) ? property.value : moment().format('YYYY-MM-DD');
            bookingDetails['from_date'] = from_date
            setBookingDetails({...bookingDetails})
            // handleResetBooking();
        }
        else if (property.slug == 'admissions-availability') {
            // handleResetBooking();
            let error = false;
            let avail_weeks = null;
            if (property && property.value && property.property_values) {
                const property_value = property.property_values.find(obj => obj.id == property.value)
                if (property_value) {
                    let avail_data = property_value.value
                    avail_data = avail_data.split(',');

                    console.log('avail_data: ', avail_data)
                    if (avail_data.length == 2 || avail_data.length == 4) {
                        avail_weeks = avail_data.map((week) => week.trim());
                        avail_weeks.map((week, index) => {
                            error = false
                            if (index == 2 || index == 3) {
                                week = avail_weeks[0] + ' ' + week
                            }

                            const parsedDateTime = moment(week, undefined, true);
                            const valid = parsedDateTime.isValid();
                            if (!valid) {
                                error = true;
                            }
                        })

                        if (!error) {
                            if (avail_data[0] && avail_data[1]) {
                                bookingDetails['from_date'] = avail_data[0]
                                bookingDetails['to_date'] = avail_data[1]
                            }

                            if (avail_data[2] && avail_data[3]) {
                                bookingDetails['from_time'] = avail_data[2]
                                bookingDetails['to_time'] = avail_data[3]
                            }

                            // console.log(bookingDetails)
                            setBookingDetails({...bookingDetails})
                        }
                    }
                }
            }
        }
    }

    function handleCheckboxChange(e, index, value) {
        // if (e) e.preventDefault();

        console.log('handleChange,index,value,e: ', index, value)
        return false;
    }

    // handle property max bookings
    const handlePropertyMaxBookings = (property = null) => {

        // check camp properties if exist
        if (!property) {
            property = properties.find(obj => obj.slug == 'max-bookings')
        }

        let max_bookings = null;
        // check and validate max_bookings value
        if (property) {
            if (property.value && property.property_values) {
                const found = property.property_values.find(obj => obj.id == property.value)
                if (found) {
                    max_bookings = found.value
                    bookingDetails['max_bookings'] = max_bookings
                    setBookingDetails({...bookingDetails})
                } else {
                    dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                    return false;
                }
            } else {
                dispatch({type: 'SHOW-ERROR', payload: 'Please select correct camp days.'})
                return false;
            }
        }

        return max_bookings;
    }

    // Handle item add to cart
    const handleAddToCart = (e, item_id) => {
        if (e) e.preventDefault();

        const payload = {
            pcl_id: global.pcl.id,
            item_id: item_id || itemId,
            category_id: categoryId || '',
            order_id: global.order_id || '',
            quantity: quantity,
        }

        const properties_clone = cloneDeep([...properties])
        properties_clone.map((property, index) => {
            let property_value = '-';
            if (property.property_type == 'select') {
                const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
                properties_clone[index].value = (f_property) ? f_property.id : '';
            }

        })

        // console.log('Quantity', quantity)
        // return false;

        payload['properties'] = properties_clone
        if (e) {
            setProperties([...properties_clone])
        } else {
            payload['properties'] = []
        }

        const data = new FormData()
        data.append('pcl_id', global.pcl.id)
        data.append('customer_id', payload['customer_id'])
        data.append('order_id', payload['order_id'])
        data.append('item_id', item_id || itemId)
        data.append('category_id', categoryId || '')
        data.append('quantity', payload['quantity'])
        data.append('request_type', 'item-add-to-cart')


        let is_booking = false;
        const is_booking_item = (bookableProperties.length > 0);
        const bookings_data = [];
        const bookings_arr = cloneDeep(bookings);
        bookings_arr.map((booking, index) => {
            // remove slots to avoid sending in post request
            delete booking.slots
            const b_admissions = booking.admissions
            if (isValidBooking(booking)) {
                is_booking = true;
                const admissions_data = []
                b_admissions.map(admission => {
                    const param_adm = {
                        admission_id: admission.id,
                        allowed_person: admission.allowed_person
                    }

                    if (admission.requested_persons != undefined && admission.requested_persons >= 0) {
                        param_adm['requested_persons'] = admission.requested_persons
                    } else if (admission.additional_persons) {
                        param_adm['additional_persons'] = admission.additional_persons
                    }

                    admissions_data.push(param_adm)
                });

                booking['status'] = 2;
                booking['admissions'] = admissions_data;
                bookings_data.push(booking)
            }
        })

        const b_count = validateBookingsCount(bookings_data)
        if (is_booking_item && bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (is_booking_item && b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        data.append('bookings', JSON.stringify(bookings_data))
        const selected_slots = selectedSlots.filter((obj) => obj.bookable_service_id && obj.booking_date)
        payload['properties'].map((property, index) => {
            if (property.property_type == 'image') {
                const img_name = `image_${index}`
                data.append(img_name, property.value)
            }
        })


        data.append('properties', JSON.stringify(payload['properties']))
        Api.post(`client/order-details`, data, (res) => {
            const data = res.data.data;
            dispatch({type: "ORDER-ID", payload: data.order_id})
            dispatch({type: 'ORDER-CUSTOMER', payload: data.customer_id ?? 0});

            const c_time = moment();
            dispatch({type: "ORDER-TIME", payload: moment(c_time).format('YYYY-MM-DD HH:mm')})

            if (is_booking) {
                let new_time = c_time.add(20, 'minutes');
                new_time = moment(new_time).format('YYYY-MM-DD HH:mm')
                dispatch({type: 'CHECKOUT-TIME', payload: new_time})
            } else {
                dispatch({type: 'CHECKOUT-TIME', payload: ''})
            }

            const cart_items_count = Number(global.cart_items_count) + 1;
            dispatch({type: 'CART-ITEMS-COUNT', 'payload': cart_items_count})
            dispatch({type: "SHOW-SUCCESS", payload: 'Item added into cart successfully.'})

            if (data.campaign_item && Object.keys(data.campaign_item).length > 0) {
                handleCampaignItem(data.order_id, data.campaign_item, cart_items_count);
            } else {
                window.location.assign('/cart')
            }

        }, (errors) => {
            setErrors(errors)
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            //dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    // Campaign Items
    const handleCampaignItem = (order_id, campaign_item, cart_items_count) => {
        console.log('campaign_item: ', campaign_item)
        if (Object.keys(campaign_item).length > 0 && campaign_item['item_quantity'] > 0) {
            let discount_type = (campaign_item['camp_discount_type'] == 'exact_discount') ? '$' : '%'

            const data = {
                pcl_id: global.pcl.id,
                order_id: global.order_id,
                item_id: campaign_item['camp_item_id'],
                category_id: campaign_item['camp_category_id'],
                price_id: campaign_item['price_id'],
                price_detail_id: campaign_item['price_detail_id'],
                quantity: campaign_item['item_quantity'],
                request_type: 'item-add-to-cart',
            }

            Swal.fire({
                toast: true,
                title: 'Congrats!',
                html: "<div>" +
                    "You have an extra discounted item with this item purchase." + "<br>" +
                    "<span class='text-success'>Item Name: " + campaign_item['item_title'] + " </span><br>" +
                    "<span class='text-danger'>Discount: " + campaign_item['camp_discount'] + discount_type + " </span><br>" +
                    "<span class='text-warning'>Item Price: $" + campaign_item['camp_item_regular_price'] + " </span><br>" +
                    "<span class='text-info'>Discount Quantity: " + campaign_item['item_quantity'] + " </span><br>" +
                    "</div>",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1daf52',
                cancelButtonColor: '#b49311',
                confirmButtonText: 'Yes, Add Item',
                width: '600px'
            }).then((result) => {
                if (result.isConfirmed) {
                    Api.post(`client/order-details`, data, (res) => {
                        const data = res.data.data
                        dispatch({type: 'CART-ITEMS-COUNT', 'payload': Number(cart_items_count) + 1})
                        dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                        window.location.assign('/cart')
                    }, (errors) => {
                        dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
                    })
                } else {
                    console.log("CANCEL BUTTON EVENT CALL")
                    window.location.assign('/cart')
                }
            })

        }
    }

    // Load all bookable services slots
    const handleLoadSlots = (b_index, property_id, booking_date) => {

        const cl_bookings = cloneDeep(bookings)
        if (booking_date) {
            slotSearchTerms['booking_date'] = booking_date
            cl_bookings[b_index]['booking_date'] = booking_date;
            setBookings([...cl_bookings])
        }

        if (!booking_date && cl_bookings[b_index]['booking_date']) {
            slotSearchTerms['booking_date'] = cl_bookings[b_index]['booking_date']
        }

        if (!slotSearchTerms['booking_date'] || slotSearchTerms['booking_date'] === '') {
            dispatch({type: 'SHOW-ERROR', payload: 'Date must be selected from booking.'})
            return false;
        }

        if (bookingDetails && bookingDetails.from_time && bookingDetails.to_time) {
            slotSearchTerms['from_time'] = bookingDetails.from_time
            slotSearchTerms['to_time'] = bookingDetails.to_time
        }

        // return false

        /*if (bookable_service_id) {
            slotSearchTerms['bookable_service_id'] = bookable_service_id
        }*/

        slotSearchTerms['booking_id'] = cl_bookings[b_index].booking_id || '';
        if (property_id) slotSearchTerms['property_id'] = property_id;
        setSlotSearchTerms(slotSearchTerms);

        const qParams = "?" + new URLSearchParams(slotSearchTerms).toString();
        Api.get("client/bookable-service-times" + qParams, (res) => {
            const sData = res.data.data;
            sData['property_id'] = property_id;
            setSlotsData({...sData})

            const b_slots = (cl_bookings[b_index] && cl_bookings[b_index].slots) ? cl_bookings[b_index].slots : [];
            const p_index = bookableProperties.findIndex(obj => obj.id == property_id)
            const bs_index = b_slots.findIndex(obj => obj.property_id == property_id)

            // console.log('cl_bookings[b_index].slots: ', cl_bookings[b_index].slots)
            // console.log('sData, bsIndex : ', bs_index, b_slots)
            // return false;

            if (bs_index <= 0) {
                cl_bookings[b_index]['slots'] = [sData];
            } else {
                console.log('b_slots, bsIndex: ', bs_index, b_slots)
                b_slots[bs_index] = sData
                cl_bookings[b_index]['slots'] = b_slots;
            }

            // Remove selected slots
            const bb_bookings = [...cl_bookings[b_index].bookings];
            bb_bookings.map((b_booking, index) => {
                if ((p_index === 0) || (p_index > 0 && index >= p_index)) {
                    bb_bookings[index]['bookable_service_id'] = null
                    bb_bookings[index]['from_time'] = null
                    bb_bookings[index]['to_time'] = null
                }
            })

            cl_bookings[b_index]['bookings'] = bb_bookings
            setBookings([...cl_bookings])

        });

    }

    // Load Bookable service slots
    const handleLoadSlotsData = (b_index, bookable_service_id = null, property_id = null, booking_date = null) => {

        const cl_bookings = cloneDeep(bookings)
        if (booking_date) {
            slotSearchTerms['booking_date'] = booking_date
            cl_bookings[b_index]['booking_date'] = booking_date;
            setBookings([...cl_bookings])
        }

        if (!booking_date && cl_bookings[b_index]['booking_date']) {
            slotSearchTerms['booking_date'] = cl_bookings[b_index]['booking_date']
        }

        if (!slotSearchTerms['booking_date'] || slotSearchTerms['booking_date'] === '') {
            dispatch({type: 'SHOW-ERROR', payload: 'Date must be selected from booking.'})
            return false;
        }

        if (bookable_service_id) {
            slotSearchTerms['bookable_service_id'] = bookable_service_id
        }

        if (property_id) slotSearchTerms['property_id'] = property_id;
        setSlotSearchTerms(slotSearchTerms)

        if (!slotSearchTerms['bookable_service_id'] || slotSearchTerms['bookable_service_id'] === '') {
            return false;
        }

        const qParams = "?" + new URLSearchParams(slotSearchTerms).toString();
        Api.get("client/bookable-service-times" + qParams, (res) => {
            const sData = res.data.data;
            // return false;

            const b_slots = (cl_bookings[b_index] && cl_bookings[b_index].slots) ? cl_bookings[b_index].slots : [];
            const p_index = bookableProperties.findIndex(obj => obj.id == property_id)
            const bs_index = b_slots.findIndex(obj => obj.property_id == property_id)
            console.log('sData, bsIndex : ', bs_index, b_slots)

            if (bs_index <= 0) {
                cl_bookings[b_index]['slots'] = [sData];
            } else {
                console.log('b_slots, bsIndex: ', bs_index, b_slots)
                b_slots[bs_index] = sData
                cl_bookings[b_index]['slots'] = b_slots;
            }

            // Remove selected slots
            const bb_bookings = [...cl_bookings[b_index].bookings];
            bb_bookings.map((b_booking, index) => {
                if ((p_index === 0) || (p_index > 0 && index >= p_index)) {
                    bb_bookings[index]['bookable_service_id'] = null
                    bb_bookings[index]['from_time'] = null
                    bb_bookings[index]['to_time'] = null
                }
            })

            cl_bookings[b_index]['bookings'] = bb_bookings
            setBookings([...cl_bookings])

        });

    }

    // Handle selected slot from calendar
    const handleSelectSlot = async (slot, bookable_service_id, property_id, b_index) => {
        console.log("handleSelectSlot")

        let from_time = slot.from_time;
        let to_time = slot.to_time;

        const booking = cloneDeep(bookings[b_index])
        const sBookings = cloneDeep(booking.bookings) || []
        let booking_date = moment(booking.booking_date).format('YYYY-MM-DD');

        console.log('sBookings: ', property_id, sBookings)
        let bb_index = sBookings.findIndex(obj => obj.property_id == property_id)
        const sBooking = sBookings[bb_index] || null
        if (sBookings.length === 0 || !sBooking || bb_index === -1) {
            dispatch({
                type: 'SHOW-ERROR',
                payload: `Sorry selected booking not found, Try again. ${bb_index}, ${sBookings}, ${sBooking}`
            })
            return false;
        }

        const max_booking_day = handleCheckMaxBookingsPerDayAllow(booking_date, b_index)
        if (!max_booking_day) {
            dispatch({
                type: 'SHOW-ERROR',
                payload: 'Cannot more bookings on this date, Max bookings limit per day reached.'
            })
            return false;
        }

        sBooking['bookable_service_id'] = bookable_service_id;
        sBooking['from_time'] = from_time;
        sBooking['to_time'] = to_time;
        sBookings[bb_index] = sBooking;
        // booking['bookings'] = sBookings
        // bookings[b_index] = booking;
        // setBookings([...bookings]);

        let pre_from_time = from_time
        let pre_to_time = to_time
        const b_bookings = bookings[b_index].bookings

        let booking_slot_error = false;
        for (const [bb_index, b_booking] of sBookings.entries()) {
            if (bb_index !== 0) {
                const property = bookableProperties.find(obj => obj.id == b_booking.property_id)
                // console.log('bb_index, booking: ', bb_index, b_booking)
                const property_id = property.id;
                const booking_gap = property.booking_gap;
                const ba_services = property.bookable_services;

                let space_slot_error = false;
                let shouldBreakSpacesLoop = false;
                spacesLoop: for (const [s_index, space] of ba_services.entries()) {
                    const space_id = space.value;
                    const params = {
                        pcl_id: global.pcl.id,
                        property_id: property_id,
                        bookable_service_id: space_id,
                        booking_date: booking_date
                    }

                    if (bookingDetails && bookingDetails.from_time && bookingDetails.to_time) {
                        params['from_time'] = bookingDetails.from_time
                        params['to_time'] = bookingDetails.to_time
                    }

                    let slot_found = false;
                    let apiUrl = Api.getApiURL();
                    const qParams = "?" + new URLSearchParams(params).toString();

                    // Request to get spaces slots
                    const res = await axios.get(apiUrl + "client/bookable-service-times" + qParams);
                    const sData = res.data.data;
                    sData['property_id'] = property_id;
                    // console.log('sData: ', sData)
                    const b_slots = bookings[b_index].slots || []

                    const bsIndex = b_slots.findIndex(obj => obj.property_id == property_id)
                    if (bsIndex >= 0) {
                        b_slots[bsIndex] = sData
                    } else {
                        b_slots.push(sData);
                    }

                    const bs_index = sData.slots.findIndex(obj => obj.id == space_id)
                    const s_slots = (bs_index >= 0) ? sData.slots[bs_index].times || [] : []

                    const pre_index = ((bb_index - 1) >= 0) ? bb_index - 1 : 0
                    let new_to_time = sBookings[pre_index].to_time;
                    for (const slot of s_slots) {
                        if (booking_gap) {
                            new_to_time = booking_gap > 0
                                ? moment(pre_to_time, 'HH:mm').add(booking_gap, 'minutes').format('HH:mm')
                                : moment(pre_to_time, 'HH:mm').subtract(Math.abs(booking_gap), 'minutes').format('HH:mm');
                        }

                        // For private bookable service
                        if (slot.from_time >= new_to_time && space.private_space && slot.total_bookings == 0) {
                            b_booking['bookable_service_id'] = space_id
                            b_booking['from_time'] = slot.from_time
                            b_booking['to_time'] = slot.to_time
                            slot_found = true;
                            break;
                        }
                            // For shared bookable service
                        //  && slot.expected_guests <= space.capacity
                        else if (!space.private_space && slot.from_time >= new_to_time) {
                            b_booking['bookable_service_id'] = space_id
                            b_booking['from_time'] = slot.from_time
                            b_booking['to_time'] = slot.to_time
                            slot_found = true;
                            break;
                        }
                    }

                    if (slot_found) {
                        space_slot_error = false;
                        sBookings[bb_index] = b_booking
                        bookings[b_index]['slots'] = b_slots;
                        // setBookings([...bookings])
                        console.log('BREAK: slot_found')
                        break;
                    } else {
                        console.log('ERROR: space_slot_error')
                        space_slot_error = true;
                    }

                } // End of spaces loop

                if (space_slot_error) {
                    booking_slot_error = true;
                    console.log('BREAK: space_slot_error')
                    // dispatch({type: 'SHOW-ERROR', payload: 'Next slots not available, Please select another slot.'})
                    break;
                }

            } // End of (bb_index !== 0) if condition
        } // End of booking loop

        if (!booking_slot_error) {
            bookings[b_index]['bookings'] = sBookings;
            setBookings([...bookings])
        } else {

            const booking_clone = cloneDeep(bookings)
            const bb_bookings = [...booking_clone[b_index].bookings];
            bb_bookings.map((booking, index) => {
                bb_bookings[index]['bookable_service_id'] = null
                bb_bookings[index]['from_time'] = null
                bb_bookings[index]['to_time'] = null
            })

            //booking_clone[b_index]['slots'] = []
            booking_clone[b_index]['bookings'] = bb_bookings
            setBookings([...booking_clone])

            console.log('BREAK: booking_slot_error')
            dispatch({type: 'SHOW-ERROR', payload: 'Next slots not available, Please select another slot.'})
            return false;
        }
    }

    const handleBookingChange = (b_index, field_name, value, admission_id = null) => {
        const c_bookings = cloneDeep(bookings);
        if (field_name == 'admission') {
            console.log('value: ', value)
            const c_admissions = cloneDeep(c_bookings[b_index].admissions);
            const a_index = c_admissions.findIndex(obj => obj.id == admission_id)
            if (a_index >= 0) {
                const admission = c_admissions[a_index];
                if (value > 0 && value <= admission.max_additional_persons) {
                    console.log('AAA1')
                    // c_admissions[a_index]['additional_persons'] = value;
                    c_admissions[a_index]['requested_persons'] = parseInt(admission.allowed_person) + parseInt(value);
                    const max_checkins = handleCheckMaxCheckins(null, 'admission');
                } else if (value === 0 || value == '' || value == ' ') {
                    // c_admissions[a_index]['additional_persons'] = 0;
                    c_admissions[a_index]['requested_persons'] = 0;
                }

                c_bookings[b_index]['admissions'] = c_admissions;
                setBookings([...c_bookings])
            }
        }
        // Booking date change
        else if (field_name === 'booking_date') {
            const booking_date = moment(value).format('YYYY-MM-DD')
            console.log('booking_date, value: ', booking_date, value)
            //return false;

            const bb_bookings = [...c_bookings[b_index].bookings];
            bb_bookings.map((booking, index) => {
                bb_bookings[index]['bookable_service_id'] = null
                bb_bookings[index]['from_time'] = null
                bb_bookings[index]['to_time'] = null
            })

            c_bookings[b_index]['booking_date'] = booking_date
            c_bookings[b_index]['slots'] = []
            c_bookings[b_index]['bookings'] = bb_bookings
            setBookings([...c_bookings])
        }
    }

    const handleBookingDateChange = (b_index, property_id, date) => {
        const c_bookings = cloneDeep(bookings);

        console.log('value: ', b_index, property_id, date)
        // return false;

        date = new Date(date)
        // date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        // let booking_date = date.getUTCFullYear() + '-' + date.getUTCMonth() + '-' + date.getUTCDate();

        let booking_date = moment(date).format('YYYY-MM-DD');

        // booking_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        booking_date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');

        console.log('booking_date: ', booking_date)
        // return false;

        const bb_bookings = [...c_bookings[b_index].bookings];
        bb_bookings.map((booking, index) => {
            bb_bookings[index]['bookable_service_id'] = null
            bb_bookings[index]['from_time'] = null
            bb_bookings[index]['to_time'] = null
        })

        c_bookings[b_index]['booking_date'] = booking_date
        c_bookings[b_index]['slots'] = []
        c_bookings[b_index]['bookings'] = bb_bookings
        setBookings([...c_bookings])

        // Load slots for all bookable services
        handleLoadSlots(b_index, property_id, booking_date)
    }

    const handleReserveBooking = (b_index) => {
        // e.preventDefault()

        const isValidBooking = handleValidateBooking(b_index);
        if (!isValidBooking) {
            return false
        }

        // return false;

        const params = {}
        params['pcl_id'] = global.pcl.id
        params['item_id'] = itemId
        params['order_id'] = global.order_id || ''
        params['order_detail_id'] = orderDetailId || ''

        const bookings_data = cloneDeep(bookings[b_index]);
        const bookings_arr = bookings_data.bookings
        const admissions_arr = bookings_data.admissions

        const admissions_data = []
        if (bookings_arr.length > 0) {
            admissions_arr.map(admission => {
                // console.log('admission.requested_persons: ', admission.requested_persons)
                const param_adm = {
                    admission_id: admission.id,
                    requested_persons: admission.requested_persons || admission.allowed_person,
                }
                admissions_data.push(param_adm)
            })
        }

        bookings_data['admissions'] = (admissions_data.length > 0) ? admissions_data : admissions_arr;
        params['booking'] = bookings_data
        // console.log(bookings_data)

        // Update bookings
        if (orderDetailId && bookings_data.id) {
            const booking_id = bookings_data.id;
            Api.put(`client/bookings/${booking_id}`, params, (res) => {
                const data = res.data.data
                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                // window.location.assign('/cart')

            }, (errors) => {
                setErrors(errors)
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }

        // Add/Reserve new booking
        else {
            // create new bookings
            Api.post(`client/bookings`, params, (res) => {
                const data = res.data.data
                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])
                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})

            }, (errors) => {
                setErrors(errors)
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }
    }

    // Handle update booking
    const handleUpdateBooking = (b_index) => {
        const params = {}
        params['pcl_id'] = global.pcl.id
        params['item_id'] = itemId
        params['order_id'] = global.order_id || ''
        params['order_detail_id'] = orderDetailId || ''

        const is_booking_item = (bookableProperties.length > 0);
        const bookings_data = cloneDeep(bookings[b_index]);
        const bookings_arr = bookings_data.bookings
        const admissions_arr = bookings_data.admissions

        const admissions_data = []
        if (is_booking_item && bookings_arr.length > 0) {
            admissions_arr.map(admission => {
                // console.log('admission.requested_persons: ', admission.requested_persons)
                const param_adm = {
                    admission_id: admission.id,
                    requested_persons: admission.requested_persons || admission.allowed_person,
                }
                admissions_data.push(param_adm)
            })
        }

        bookings_data['admissions'] = (admissions_data.length > 0) ? admissions_data : admissions_arr;
        params['booking'] = bookings_data

        if (!isValidBooking(bookings_data)) {
            dispatch({type: "SHOW-ERROR", payload: 'Enter select valid date and slots for booking.'});
            return false;
        }

        const b_count = validateBookingsCount([bookings_data])
        if (is_booking_item && b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        // return false;
        if (orderDetailId && bookings_data.id) {
            params['request_type'] = 'update-bookings'
            const booking_id = bookings_data.id;
            Api.put(`client/bookings/${booking_id}`, params, (res) => {
                const data = res.data.data
                // setCartData(data.order_details)

                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])

                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
                // window.location.assign('/cart')

            }, (errors) => {
                setErrors(errors)
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }
        else {
            params['request_type'] = 'add-bookings'
            // create new bookings
            Api.post(`client/bookings`, params, (res) => {
                const data = res.data.data
                //setCartData(data.order_details)

                const booking_copy = cloneDeep(bookings)
                booking_copy[b_index]['selected'] = false
                setBookings([...booking_copy])

                dispatch({type: "SHOW-SUCCESS", payload: res.data.message})

            }, (errors) => {
                setErrors(errors)
                dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
            })
        }
    }

    const handleUpdateCartItemProperties = (e) => {
        e.preventDefault();

        console.log('handleUpdateCartItemProperties: ')
        properties['pcl_id'] = global.pcl.id
        properties['order_id'] = global.order_id
        setProperties(properties)

        let request_data = {};
        request_data['pcl_id'] = global.pcl.id
        request_data['customer_id'] = (global.user && global.user.id) ? global.user.id : ''
        request_data['order_id'] = global.order_id
        request_data['item_id'] = itemId
        request_data['properties'] = properties

        const data = new FormData()
        data.append('pcl_id', global.pcl.id)
        data.append('customer_id', request_data['customer_id'])
        data.append('order_id', global.order_id)
        data.append('item_id', itemId)
        data.append('_method', 'put')

        request_data['properties'].map((property, index) => {
            if (property.property_type == 'image') {
                const img_name = `image_${index}`
                data.append(img_name, property.value)
            }
        })

        const req_properties = request_data['properties'].filter(prop => prop.property_type != 'bookable-services')

        const properties_clone = cloneDeep([...req_properties])
        properties_clone.map((property, index) => {
            if (property.property_type == 'select') {
                const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
                properties_clone[index].value = (f_property) ? f_property.id : null;
            }

        })

        request_data['properties'] = properties_clone
        data.append('properties', JSON.stringify(request_data['properties']))

        const b_count = validateBookingsCount(bookings)
        if (bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        // if (!orderDetailId) return false;
        Api.post(`client/order-details/${orderDetailId}`, data, (res) => {
            const data = res.data.data
            dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
            window.location.assign('/cart')
        }, (errors) => {
            //handleErrors(errors, "Error: " + JSON.stringify(errors))
            setErrors(errors)
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleContinueButton = (e) => {
        e.preventDefault();

        const b_count = validateBookingsCount(bookings)
        if (bookingDetails.max_bookings_required && b_count < bookingDetails.max_bookings) {
            dispatch({
                type: "SHOW-ERROR",
                payload: 'You must need to add ' + bookingDetails.max_bookings + ' bookings to proceed.'
            })
            return false;
        }

        if (b_count == 0) {
            dispatch({type: "SHOW-ERROR", payload: 'At least one booking is required.'});
            return false;
        }

        console.log('bookingDetails: ', bookingDetails)
        // return false

        window.location.assign('/cart')
    }

    const handleLoadBookingDatesAvailability = (from_date, to_date) => {

        let qryParams = {
            pcl_id: global.pcl.id,
            item_id: item_id,
            from_date: from_date,
            to_date: to_date,
        }

        const qParams = '?' + new URLSearchParams(qryParams).toString();
        Api.get(`client/booking-dates-availability` + qParams, (res) => {
            const data = res.data.data
            // setItem(data)
        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleMonthChange = (date, type = null) => {
        console.log('handleMonthChange: ', date)
        date = moment(date).format('YYYY-MM-DD')
        setCurrentMonth(date);
        handleCheckAvailability(date)
    }

    function convertLocalToUTCDate(date) {
        if (!date) {
            return null
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        console.log('Date.UTC: ', date)
        return date
    }

    // const availableDates = [
    //     moment('2024-06-10').format('YYYY-MM-DD'),
    //     moment('2024-06-15').format('YYYY-MM-DD'),
    //     moment('2024-06-20').format('YYYY-MM-DD'),
    //     moment('2024-06-25').format('YYYY-MM-DD')
    // ]

    const isDateAvailable1 = (date) => {
        return availableDates.some(availableDate => moment(date).isSame(availableDate, 'day'));
    }

    const isDateAvailable = (date) => {
        const today = moment().add(bookingDetails.advance_booking_days || 0, "days");
        // Check if the date is greater than today's date
        if ((moment(date).isSameOrAfter(today, 'day')) && availableDates.some(availableDate => moment(date).isSame(availableDate, 'day'))) {
            return true;
        }
        // Check if the date is in the list of available dates
        // return ;
    }

    const isDayBlocked = (date) => {
        return !availableDates.some(availableDate => moment(date).isSame(availableDate, 'day'));
    }

    /*const get_dependent_properties = (property) => {
        const property_id = property.id;
        const dependent_properties = properties.map((dProperty, dIndex) => {
            const dOProperty = dProperty.depends_on_property || null;

            const pType = dProperty.property_type;
            const isBookableService = (pType === 'bookable-services');
            let col = (isBookableService) ? 12 : dProperty.property_size;
            col = (pType === 'line-break' || pType === 'section-break') ? 12 : col
            const req_label_class = (dProperty.required) ? 'req-label' : '';

            if (dOProperty && dOProperty.depends_on_property_id == property_id && dOProperty.depends_on_property_value == property.value) {
                const dpType = dProperty.property_type
                return (
                    <Col key={dProperty.id} sm={col} className='bg-light-3 mb-2'>

                        {(dpType === 'section-break') &&
                            <div className='property-section-break'>{dProperty.value}</div>
                        }

                        {(dpType === 'text' || dpType === 'number' || dpType === 'time') &&
                            <Form.Group className="mb-3 custom-input-style" controlId="name">
                                <Form.Label className={`${req_label_class}`}>{dProperty.name}</Form.Label>
                                <Form.Control size='md' type={dpType} className={(errors[dProperty.slug]) && 'is-invalid'}
                                              defaultValue={(dpType == 'text' || dpType == 'number') ? dProperty.value : null}
                                              onChange={(e) => handleChange(e, dIndex, e.target.value)}
                                />
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </Form.Group>
                        }

                        {(dpType === 'date') &&
                            <Form.Group className="mb-3 custom-input-style" controlId="name">
                                <Form.Label className={`${req_label_class}`}>{dProperty.name}</Form.Label>
                                <Form.Control size='md' type='date' className={(errors[dProperty.slug]) && 'is-invalid'}
                                              value={moment(dProperty.value).format('YYYY-MM-DD')}
                                              onChange={(e) => handleChange(e, dIndex, e.target.value)}
                                />
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </Form.Group>
                        }

                        {(dpType === 'select' && dProperty.property_values.length > 0) &&
                            <Form.Group className="mb-3 custom-input-style" controlId="name_2">
                                <Form.Label className={`${req_label_class}`}>{dProperty.name}</Form.Label>
                                <Form.Select size='md' defaultValue={dProperty.value}
                                             className={(errors[dProperty.slug]) && 'is-invalid'}
                                             onChange={(e) => handleChange(e, dIndex, e.target.value, 'select')}
                                >

                                    <option value=''>---</option>
                                    {dProperty.property_values.map((value) => (
                                        <option key={`s-${value.id}`} value={value.id}>
                                            {value.name}
                                            {(value.price != null) &&
                                                <>
                                                    ({`$${value.price} ${(value.price >= 0) ? 'extra' : 'discount'}`})
                                                </>
                                            }
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </Form.Group>
                        }

                        {(dpType === 'radio' && dProperty.property_values.length > 0) &&
                            <div style={{marginTop: '34px'}}>
                                {dProperty.property_values.map((option) => (
                                    <Form.Check key={`inline-${option.id}`} inline name="radio1" type='radio' id={`inline-${option.id}`}
                                                className={(errors[dProperty.slug]) && 'is-invalid'}
                                                label={`${option.name} ($${option.price})`}
                                                defaultValue={option.id}
                                                onChange={(e) => handleChange(e, dIndex, e.target.value)}
                                    />
                                ))}
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </div>
                        }

                        {(dpType === 'checkbox') &&
                            <div style={{marginTop: '34px'}}>
                                <Form.Group className="mb-3 custom-input-style checkbox-lg" controlId="checkbox">
                                    <Form.Check type="checkbox" size='md' label={dProperty.name}
                                                className={(errors[dProperty.slug]) && 'is-invalid'}
                                                defaultChecked={dProperty.value}
                                                onChange={(e) => handleChange(e, dIndex, e.target.checked)}
                                                required={dProperty.required}/>
                                </Form.Group>
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </div>
                        }

                        {(dpType === 'textarea') &&
                            <Form.Group className="mb-3 custom-input-style" controlId="description">
                                <Form.Label className={`${req_label_class}`}>{dProperty.name}</Form.Label>
                                <Form.Control size='md' as="textarea" placeholder='Description' rows={2}
                                              className={(errors[dProperty.slug]) && 'is-invalid'}
                                              defaultValue={dProperty.value}
                                              onChange={(e) => handleChange(e, dIndex, e.target.value)}
                                />
                                <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                            </Form.Group>
                        }

                        {(dpType === 'image') &&
                            <div>
                                <Form.Group className="mb-3 custom-input-style" controlId="image">
                                    <Form.Label className={`${req_label_class}`}>{dProperty.name}</Form.Label>
                                    <Form.Control type="file" size='md' className={(errors[dProperty.slug]) && 'is-invalid'}
                                                  placeholder="select image"
                                                  onChange={(e) => handleImageChange(e, dIndex)}
                                    />
                                    <Form.Text className="text-muted fs-11x">{dProperty.description}</Form.Text>
                                    <div className="invalid-feedback">{errors[dProperty.slug]}</div>
                                </Form.Group>

                                {(dProperty.image_url) &&
                                    <div className='property-img-preview'>
                                        <img src={dProperty.image_url} alt="preview" style={{width: '100%', height: '100%'}}/>
                                    </div>
                                }
                            </div>
                        }

                    </Col>
                )
            }
        });

        return dependent_properties;
    }*/

    const dependency_check = (property) => {
        let dependency = true;
        if (property.depends_on_property) {
            dependency = false;
            const parentProp = properties.find(p => p.id === property.depends_on_property.depends_on_property_id);

            if (parentProp && property.depends_on_property.depends_on_property_value == parentProp.value) {
                dependency = dependency_check(parentProp); //Nested call
            }
            if (property.slug === 'theme-name') {
                console.log("Prop Name:" + property.name + ", Dependency:" + dependency);
            }
        }

        return dependency;
    }

    let isBookingDisplayed = 0;
    let isBookingSelected = bookings.findIndex(obj => obj.selected === true);

    const from_date_pro = properties.find(obj => obj.slug === 'from_date')
    const max_bookings_pro = properties.find(obj => obj.slug === 'max-bookings')
    const admissions_avail_pro = properties.find(obj => obj.slug === 'admissions-availability')
    const isBookingEditable = ((from_date_pro && !from_date_pro.value) || (max_bookings_pro && !max_bookings_pro.value) || (admissions_avail_pro && !admissions_avail_pro.value)) ? false : true;

    console.log('bookableProperties: ', bookableProperties)
    return (
        <div className=''>
            <Container className='bg-white min-vh-100'>

                {/* Cart title */}
                <div className='d-flex align-items-center gap-2 flex-wrap bb-1px'>
                    <div className='fw-bold fs-22x text-light-green'>{item.name}</div>
                    {(item.description_short) &&
                        <div className='fs-16x'>({item.description_short})</div>
                    }
                </div>

                {/* Max bookings note */}
                {(bookingDetails.max_bookings > 1 || bookingDetails.max_bookings_per_day > 1) &&
                    <div className='booking-note mt-2 mb-1'>
                        <span className='fw-bold'>Note: </span>
                        <span>Maximum {bookingDetails.max_bookings} bookings allowed,</span>&ensp;
                        <span>Maximum {bookingDetails.max_bookings_per_day} bookings/day allowed</span>
                    </div>
                }

                <div>
                    <Row className='mt-4 justify-content-center'>
                        {/* Item properties */}
                        {properties.map((property, index) => {
                            if (!dependency_check(property)) {
                                return;
                            }
                            const property_id = property.id;
                            const pType = property.property_type
                            // if (property.depends_on_property && property.depends_on_property.id) return null

                            const as_combine = property.as_combine;
                            const bServices = property.bookable_services;
                            const res_error = response.find(obj => obj.property_id == property.id)

                            let isBookbleService = false;
                            if (pType === 'bookable-services') {
                                isBookingDisplayed++;
                                isBookbleService = true;
                            }
                            let col = (isBookbleService) ? 12 : property.property_size;

                            if (property.slug === 'from_date' && property.value === 'TODAY') {
                                handleChange(undefined, index, moment().format('YYYY-MM-DD'))
                            }

                            col = (pType === 'line-break' || pType === 'section-break') ? 12 : col
                            const req_label_class = (property.required) ? 'req-label' : '';
                            const is_required = property.required;

                            let isSpecialProperty = '';
                            const isSpecialBookingProperty = (property.slug === 'from_date' || property.slug === 'max-bookings' || property.slug === 'admissions-availability');
                            isSpecialProperty = (isSpecialBookingProperty) ? true : null;

                            let property_value = '-';
                            if (pType === 'select') {
                                const f_property = property.property_values.find(obj => obj.value == property.value || obj.id == property.value || obj.name == property.value)
                                if (f_property) property_value = f_property.id
                            } else if (pType !== 'bookable-services') {
                                property_value = property.value;
                            }
                            if (!orderDetailId && !property.value) {
                                //load property default value
                                property.value = property.property_values.find(item => item.is_default)?.id ?? null;
                            }
                            // Show normal property
                            return [
                                <>
                                {/*(!property.depends_on_property || !property.depends_on_property.id) &&*/
                                    <>
                                        <Col key={property.id} md={col} className={`col-md-${col} mb-2`}>

                                        {(pType == 'section-break') &&
                                            <div className='property-section-break'>{property.value}</div>
                                        }

                                        {/* Bookings Details */}
                                        {(isBookbleService && isBookingDisplayed === 1) &&
                                            <Row className=''>

                                                {/* To show list of bookings */}
                                                <Col md={12}>
                                                    <div>
                                                        {bookings.map((booking, index) => {
                                                            if (booking.selected || booking.status == 5) return null;
                                                            let s_slot = {}
                                                            const b_bookings = booking.bookings;
                                                            const b_admissions = booking.admissions;
                                                            return (
                                                                <Col md={12}>
                                                                    <div key={index + 20}
                                                                         className={`booking-short-view d-flex flex-wrap justify-content-between align-items-center mb-2`}
                                                                         style={{
                                                                             padding: '10px',
                                                                             backgroundColor: 'lightgray'
                                                                         }}>

                                                                        {/* Booking date */}
                                                                        <div className=''>
                                                                            <span>Booking Date: </span>
                                                                            <span
                                                                                className='fw-bold'>{(booking.booking_date) ? moment(booking.booking_date).format('DD-MM-YYYY') : '-'}</span>
                                                                        </div>

                                                                        {/* Selection booking space */}
                                                                        {b_bookings.map((b_booking, b_index) => {
                                                                            s_slot['from_time'] = booking.from_time
                                                                            s_slot['to_time'] = booking.to_time
                                                                            const property = bookableProperties.find(obj => obj.id == b_booking.property_id)
                                                                            const bServices = (property) ? property.bookable_services : [];
                                                                            const space = bServices.find(obj => obj.value == b_booking.bookable_service_id) || {}
                                                                            return (
                                                                                <div className=''>
                                                                                    <span>{b_booking.name}: </span>
                                                                                    <span
                                                                                        className='fw-bold me-1'>({(space.name) && space.name + ':'}</span>
                                                                                    <span className='fw-bold'>
                                                                                            {(b_booking.from_time) ? moment(b_booking.from_time, 'HH:mm').format('hh:mma') : '-'}
                                                                                        -
                                                                                        {(b_booking.to_time) && moment(b_booking.to_time, 'HH:mm').format('hh:mma')})
                                                                                        </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }

                                                                        <div className=' text-end mt-2'>
                                                                            {(!bookings.find(b => b.selected === true)) &&
                                                                                <>
                                                                                    {(bookings.length > 1) &&
                                                                                        <button type='button'
                                                                                                className='btn btn-sm btn-danger me-1'
                                                                                                onClick={(e) => handleRemoveBooking(index)}>
                                                                                            <XCircleFill/>
                                                                                        </button>
                                                                                    }

                                                                                    <button type='button'
                                                                                            className='btn btn-sm btn-success me-2'
                                                                                            onClick={(e) => handleSetEditBooking(index)}>
                                                                                        <PencilSquare/>
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </Col>

                                                {/* Add/Edit bookings section */}
                                                {bookings.map((booking, index) => {

                                                    if (!booking.selected || !isBookingEditable) return null;

                                                    let s_slot = {}
                                                    const b_slots = booking.slots || [];
                                                    const b_bookings = booking.bookings;
                                                    const b_admissions = booking.admissions;

                                                    return (
                                                        <Col md={12}>
                                                            <div key={index + 20}
                                                                 className={`bookings-list ${(booking.booking_date) ? 'pb-2' : 'pb-2'}`}>

                                                                {/* Booking date */}
                                                                <div className='mt-3 d-flex justify-content-center'>
                                                                    <div className="form-group">
                                                                        <div
                                                                            className='text-center fs-17x fw-bold text-dark-light'>Select
                                                                            Booking Date
                                                                        </div>


                                                                        {/*<SingleDatePicker
                                                                                date={(booking.booking_date) ? moment(booking.booking_date) : null}
                                                                                focused={focused}
                                                                                onFocusChange={({ focused }) => {
                                                                                    setFocused(focused)
                                                                                    if (focused) handleMonthChange(booking.booking_date || moment())

                                                                                }}
                                                                                onDateChange={(date) => handleBookingDateChange(index, property.id, date)}
                                                                                onNextMonthClick={(date) => handleMonthChange(date, "next")}
                                                                                onPrevMonthClick={(date) => handleMonthChange(date, "previous")}
                                                                                numberOfMonths={1}
                                                                                isDayBlocked={isDayBlocked}
                                                                                displayFormat="DD-MM-YYYY"
                                                                                id="your_unique_id" // PropTypes.string.isRequired,
                                                                            />*/}


                                                                        <div className="custom-date-picker-container">
                                                                            <DatePicker
                                                                                className="form-control custom-date-picker-input w-100"
                                                                                dateFormat="yyyy-MM-dd"
                                                                                filterDate={isDateAvailable}
                                                                                selected={booking.booking_date}
                                                                                onMonthChange={handleMonthChange}
                                                                                onCalendarOpen={() => handleMonthChange(booking.booking_date || moment())}
                                                                                onChange={(date) => handleBookingDateChange(index, property.id, date)}
                                                                            />

                                                                            <CalendarMinus
                                                                                className="custom-date-picker-icon"
                                                                                onClick={() => document.querySelector('.custom-date-picker-input').focus()}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                {(booking.booking_date) &&
                                                                    <>
                                                                        {/* Bookings Admissions Details */}
                                                                        <div>
                                                                            <div
                                                                                className='section-heading-1 mt-4 fs-18x text-center text-dark-light'>Admissions:
                                                                            </div>
                                                                            <div
                                                                                className='d-flex gap-2 flex-wrap mt-2 justify-content-center'>
                                                                                <div className='fw-bold fs-18x'>Included
                                                                                    :
                                                                                </div>
                                                                                <div className='d-flex gap-2'>
                                                                                    {b_admissions.map((adm, idx) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={idx}>{adm.name}: {adm.allowed_person}</div>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>

                                                                            {/* Additional Admissions */}
                                                                            <div
                                                                                className='d-flex mt-2 gap-2 flex-wrap justify-content-center'>
                                                                                {b_admissions.map((adm, idx) => {
                                                                                    if (adm.max_additional_persons > 0) {
                                                                                        const additional_person = (adm.requested_persons >= 0) ? adm.requested_persons - adm.allowed_person : adm.additional_persons || 0;
                                                                                        return (
                                                                                            <div key={idx}
                                                                                                 className='me-2'>
                                                                                                <label>Add
                                                                                                    Additional {adm.name.slice(0, 8)}</label>
                                                                                                <select
                                                                                                    className='form-select'
                                                                                                    style={{maxWidth: '183px'}}
                                                                                                    value={additional_person}
                                                                                                    onChange={(e) => handleBookingChange(index, 'admission', e.target.value, adm.id)}>
                                                                                                    <option value=''>0
                                                                                                    </option>
                                                                                                    {[...Array(adm.max_additional_persons + 1).keys()].slice(1).map(i => (
                                                                                                        <option key={i}
                                                                                                                value={i}>{i} (${adm.price_per_person * i})</option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        {/* Admissions end */}

                                                                        {/* Slots colors description */}
                                                                        <div className='d-flex gap-3 mt-2 flex-wrap'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div
                                                                                    className='desc-color-box-available me-2'></div>
                                                                                <div className=''>Available</div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div
                                                                                    className='desc-color-box-selected me-2'></div>
                                                                                <div className=''>Selected</div>
                                                                            </div>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div
                                                                                    className='desc-color-box-unavailable me-2'></div>
                                                                                <div className=''>Unavailable</div>
                                                                            </div>
                                                                        </div>
                                                                        {/* END Slots colors description */}

                                                                        {/* Booking space selection */}
                                                                        <div className='d-flex gap-3 flex-wrap mt-2'>
                                                                            {b_bookings.map((b_booking, b_index) => {
                                                                                const bService_id = b_booking.bookable_service_id;
                                                                                s_slot['from_time'] = b_booking.from_time
                                                                                s_slot['to_time'] = b_booking.to_time
                                                                                const property = bookableProperties.find(obj => obj.id == b_booking.property_id)

                                                                                const as_combine = false; /*(property) ? property.as_combine : false*/
                                                                                const bServices = (property) ? property.bookable_services : [];
                                                                                return (
                                                                                    <div key={b_index}
                                                                                         className='space-item d-flex justify-content-center'>
                                                                                        <div className='w-100'>

                                                                                            {/* Bookable services */}
                                                                                            <div className='mt-3'>
                                                                                                <div
                                                                                                    className='fw-bold mb-1 bookable-property-title'>{b_booking.name}</div>

                                                                                                <div className=''>
                                                                                                    {bServices.map((service, ind) => {
                                                                                                        const bookable_service_id = service.value

                                                                                                        let images = service.images || null;
                                                                                                        let space_slots = [];
                                                                                                        const booking_slots = booking.slots
                                                                                                        const property_slots = booking_slots.find(obj => obj.property_id == property.id)
                                                                                                        if (property_slots) {
                                                                                                            const slots_spaces = property_slots.slots || []
                                                                                                            const space_found = slots_spaces.find(obj => obj.id == bookable_service_id);
                                                                                                            space_slots = (space_found) ? space_found.times : []
                                                                                                        }

                                                                                                        // console.log('space_slots', space_slots)

                                                                                                        return (
                                                                                                            <div
                                                                                                                className='d-flex align-items-center gap-3 mb-3 bookable-service-section flex-wrap'>
                                                                                                                <div
                                                                                                                    className='bookable-service-cart bs-list-item1 '>
                                                                                                                    <div
                                                                                                                        style={{width: '117px'}}>
                                                                                                                        <div
                                                                                                                            className='bookable-space-img'>
                                                                                                                            <img
                                                                                                                                style={{maxWidth: '100%'}}
                                                                                                                                src={(images && images.length > 0) ? images[0].url : room_default_img}/>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            key={ind}
                                                                                                                            className={`bookable-space ${(bookable_service_id == bService_id) && 'active'}`}
                                                                                                                            onClick={() => {
                                                                                                                                // (b_index !== -1) ?
                                                                                                                                //     handleLoadSlotsData(index, bService_id, booking.property_id)
                                                                                                                                //     :
                                                                                                                                //     dispatch({type: 'SHOW-ERROR', payload: `Sorry, you can only choose ${b_bookings[0].name} and next available slots will be auto select.`})
                                                                                                                            }}>{service.name}</div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {/* Bookable service slots */}
                                                                                                                <div
                                                                                                                    className='bs-list-item2'>
                                                                                                                    {/* Slots selection */}
                                                                                                                    <div
                                                                                                                        className='d-flex mt-1 gap-2 flex-wrap slots-list'>
                                                                                                                        {/* Check if not any slot */}
                                                                                                                        {(space_slots.length === 0) &&
                                                                                                                            <div
                                                                                                                                className='mt-2'>Booking
                                                                                                                                slot
                                                                                                                                available.</div>
                                                                                                                        }

                                                                                                                        {space_slots.map((slot, sIndex) => {
                                                                                                                            //const time_slot = {from_time: time.from_time, to_time: time.to_time}
                                                                                                                            const active_slot = (bService_id == bookable_service_id && s_slot.from_time >= slot.from_time && s_slot.to_time <= slot.to_time)
                                                                                                                            const slot_available = slot.available

                                                                                                                            const slot_time = moment(slot.from_time, 'HH:mm').format('hh:mma') + ' - ' + moment(slot.to_time, 'HH:mm').format('hh:mma')

                                                                                                                            return (
                                                                                                                                <div
                                                                                                                                    key={sIndex}
                                                                                                                                    data-tooltip-id='tooltip'
                                                                                                                                    data-tooltip-content={slot_time}
                                                                                                                                    className={`space-slot ${(active_slot) && 'active'} ${(!slot_available) && 'disabled'}`}
                                                                                                                                    onClick={() => {
                                                                                                                                        (b_index === 0 && slot_available) ?
                                                                                                                                            handleSelectSlot(slot, bookable_service_id, b_booking.property_id, index)
                                                                                                                                            :
                                                                                                                                            (slot_available) ?
                                                                                                                                                dispatch({
                                                                                                                                                    type: 'SHOW-ERROR',
                                                                                                                                                    payload: `Sorry, you can only chose ${b_bookings[0].name} slot and next available slots will be auto select.`
                                                                                                                                                })
                                                                                                                                                :
                                                                                                                                                dispatch({
                                                                                                                                                    type: 'SHOW-ERROR',
                                                                                                                                                    payload: `Sorry, selected slot not available.`
                                                                                                                                                })
                                                                                                                                    }}>
                                                                                                                                    {moment(slot.from_time, 'HH:mm').format('hh:mm a')}
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        }
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                    }
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                            }
                                                                        </div>

                                                                    </>
                                                                }


                                                                {/* Remove bookings buttons */}
                                                                <div className='mt-5 text-center mb-2'>
                                                                    {(booking.selected && orderDetailId) &&
                                                                        <button type='button'
                                                                                className='btn btn-sm btn-primary me-2'
                                                                                onClick={(e) => handleUpdateBooking(index)}>
                                                                            Save Booking Changes
                                                                        </button>
                                                                    }

                                                                    {(booking.selected && !orderDetailId) &&
                                                                        <>
                                                                            <button type='button'
                                                                                    className='btn btn-sm btn-primary me-2'
                                                                                    onClick={(e) => handleCloseEdit(index)}>
                                                                                Update Booking
                                                                            </button>

                                                                            {/*<button type='button' className='btn btn-sm btn-warning me-2' onClick={(e) => handleCancelEdit(index)}>
                                                                                    Cancel Edit
                                                                                </button>*/}
                                                                        </>
                                                                    }

                                                                    {(orderDetailId && booking.id) &&
                                                                        <button type='button'
                                                                                className='btn btn-sm btn-warning me-2'
                                                                                onClick={(e) => handleCancelBookingChanges(index)}>
                                                                            Cancel Changes
                                                                        </button>
                                                                    }

                                                                    {(bookings.length > 1) &&
                                                                        <button type='button'
                                                                                className='btn btn-sm btn-danger'
                                                                                onClick={(e) => handleRemoveBooking(index)}>
                                                                            <XCircleFill/>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                }

                                                {/* Show this table if no any booking found */}
                                                {(bookings.length == 0) &&
                                                    <div className='bookings-list'>
                                                        <div className='text-center'>Click on add booking to reserve
                                                            slot
                                                        </div>
                                                    </div>
                                                }
                                            </Row>
                                        }

                                        {(isBookingDisplayed === 1 && bookingErrors.length > 0) &&
                                            <Alert variant="danger" onClose={() => setBookingErrors([])} dismissible>
                                                <Alert.Heading>Booking errors!</Alert.Heading>
                                                <p>{Services.getErrorList(bookingErrors)}</p>
                                            </Alert>
                                        }

                                        {/* Add new booking button  */}
                                        {(isBookingDisplayed === 1 && isBookingSelected === -1 && bookableProperties.length > 0 && bookingDetails.max_bookings != 1 && handleCheckMaxCheckins(bookings)) &&
                                            <div className='d-flex justify-content-center'>
                                                <button type='button' className='btn btn-success'
                                                        onClick={() => handleSetBooking()}>
                                                    <Plus size={16}/>Add New Booking
                                                </button>
                                            </div>
                                        }

                                        {(isBookingSelected === -1 || isSpecialBookingProperty) &&
                                            <>
                                                {/* End of bookable services */}
                                                {(pType == 'text' || pType == 'number' || pType == 'time') &&
                                                    <Form.Group className="mb-3 custom-input-style" controlId="name">
                                                        <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                                        <Form.Control size='md' type={pType}
                                                                      className={(errors[property.slug]) && 'is-invalid'}
                                                                      value={(pType == 'text' || pType == 'number') ? property.value : null}
                                                                      onChange={(e) => handleChange(e, index, e.target.value)}
                                                        />
                                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </Form.Group>
                                                }

                                                {(pType == 'date') &&
                                                    <Form.Group className="mb-3 custom-input-style" controlId="name">
                                                        <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                                        <Form.Control size='md' type={pType}
                                                                      className={(errors[property.slug]) && 'is-invalid'}
                                                                      value={moment(property.value).format('YYYY-MM-DD')}
                                                                      onChange={(e) => handleChange(e, index, e.target.value)}
                                                        />
                                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </Form.Group>
                                                }

                                                {(pType == 'select' && property.property_values.length > 0) &&
                                                    <Form.Group className="mb-3 custom-input-style" controlId="name_2">
                                                        <Form.Label className={`${req_label_class} `}>{property.name}</Form.Label>
                                                        <Form.Select size='md' value={property_value}
                                                                     className={(errors[property.slug]) && 'is-invalid'}
                                                                     onChange={(e) => handleChange(e, index, e.target.value, 'select')}
                                                        >

                                                            <option value=''>---</option>
                                                            {property.property_values.map((value) => (
                                                                <option key={`s-${value.id}`} value={value.id}>
                                                                    {(value.quantity > 1 ? (value.quantity + ' ') : '') + value.name}
                                                                    {(value.price != null && value.price !== 0) &&
                                                                        <>&nbsp;({`$${value.price * value.quantity} ${(value.price >= 0) ? 'extra' : 'discount'}`})</>
                                                                    }
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </Form.Group>
                                                }

                                                {(pType == 'radio' && property.property_values.length > 0) &&
                                                    <div style={{marginTop: '37px'}}>
                                                        {property.property_values.map((option) => (
                                                            <Form.Check key={`inline-${option.id}`} inline name="radio1"
                                                                        className={(errors[property.slug]) && 'is-invalid'}
                                                                        label={`${option.name} ($${option.price})`}
                                                                        value={option.id}
                                                                        type='radio' id={`inline-${option.id}`}
                                                                        onChange={(e) => handleChange(e, index, e.target.value)}
                                                            />
                                                        ))}
                                                        <Form.Text
                                                            className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </div>
                                                }

                                                {(pType == 'checkbox') &&
                                                    <div style={{marginTop: '37px'}}>
                                                        <Form.Group className="mb-3 custom-input-style checkbox-lg" controlId="checkbox">
                                                            <Form.Check type="checkbox" size='ld' label={property.name}
                                                                        className={(errors[property.slug]) && 'is-invalid'}
                                                                        defaultChecked={!!(property.value)}
                                                                        onChange={(e) => handleChange(e, index, e.target.checked)}
                                                            />
                                                        </Form.Group>
                                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </div>
                                                }

                                                {(pType == 'textarea') &&
                                                    <Form.Group className="mb-3 custom-input-style" controlId="description">
                                                        <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                                        <Form.Control size='md' as="textarea" placeholder={property.name}
                                                                      rows={2}
                                                                      className={(errors[property.slug]) && 'is-invalid'}
                                                                      value={property.value}
                                                                      onChange={(e) => handleChange(e, index, e.target.value)}
                                                        />
                                                        <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                        <div className="invalid-feedback">{errors[property.slug]}</div>
                                                    </Form.Group>
                                                }

                                                {(pType == 'image') &&
                                                    <div>
                                                        <Form.Group className="mb-3 custom-input-style" controlId="image">
                                                            <Form.Label className={`${req_label_class}`}>{property.name}</Form.Label>
                                                            <Form.Control type="file" size='md' placeholder="select image"
                                                                          className={(errors[property.slug]) && 'is-invalid'}
                                                                          onChange={(e) => handleImageChange(e, index)}
                                                            />
                                                            <Form.Text className="text-muted fs-11x">{property.description}</Form.Text>
                                                            <div className="invalid-feedback">{errors[property.slug]}</div>
                                                        </Form.Group>

                                                        {property.image_url &&
                                                            <img src={property.image_url} alt="preview" style={{width: '70px'}}/>
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                    </Col>
                                    </>
                                }
                                </>,

                                <>
                                    {/*Check && Show dependable property*/}
                                    {/*get_dependent_properties(property)*/}
                                </>
                            ]
                        })}

                    </Row>

                    {/* Show errors */}
                    <div>
                        {(errorsArr.length > 0) &&
                            <div className="alert alert-danger p-8-10x" role="alert">
                                {errorsArr.map((error, index) => {
                                    return (
                                        <div key={index} className='text-danger'><strong>{`${index + 1}. `}</strong>
                                            {error}</div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className='text-end mt-3 border-top p-2'>
                        {(((bookableProperties.length >= 1 && !bookings.find(b => b.selected === true) && validateBookingsCount(bookings) >= 1) &&
                                (bookableProperties.length === properties.length || propertyChange)) || (bookableProperties.length === 0 && propertyChange)) &&
                            <>
                                <Button variant="primary" size='md' className='me-1' onClick={(e) =>
                                    (orderDetailId) ? handleUpdateCartItemProperties(e) : handleAddToCart(e, itemId)}>
                                    {(orderDetailId) ? 'Save Changes' : 'Save Item '}
                                </Button>
                            </>
                        }

                        {(!propertyChange && !bookings.find(b => b.selected === true) && orderDetailId) &&
                            <Button type="button" variant="primary" size='md' className='me-1'
                                    onClick={(e) => handleContinueButton(e)}>
                                Continue
                            </Button>
                        }

                        <a href={(orderDetailId) ? '/cart' : '/pos'} className='btn btn-secondary me-1'>Cancel</a>
                    </div>
                </div>

            </Container>
        </div>
    )
}