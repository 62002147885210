import React, {useContext, useEffect} from "react";
import {Link} from 'react-router-dom';
import {GlobalContext} from "../_services/global-context";
import URL from "../_services/routes";

export default function Logout () {
    const [global, dispatch] = useContext(GlobalContext);

    // Effects
    useEffect(() => {
        dispatch({type: 'USER', payload: {}})
        dispatch({type: 'TOKEN', payload: ''})
        dispatch({type: 'ORDER-ID', payload: ''})
        dispatch({type: 'ORDER-TIME', payload: ''})
        dispatch({type: 'CHECKOUT-TIME', payload: ''})
        dispatch({type: 'ORDER-CUSTOMER', payload: 0})
        dispatch({type: 'CART-ITEMS-COUNT', payload: 0})
        dispatch({type: 'SHIPPING-ADDRESS', payload: false})
        localStorage.removeItem("user_pcl");

        let return_url = (global.source === 'web') ? global.returning_url : '/';
        const redirect_base = URL.getRedirectBase();
        setTimeout(() => {
            window.location.assign(redirect_base + return_url);
        }, 500);

    }, [])

        return (
            <div style={{textAlign: 'center'}}>
                <span style={{color:'black'}}>You are logged out successfully.</span>
                <br/>
                <br/>
                <Link className="btn btn-success btn-block" role="button" to="/login">Login</Link>
            </div>
        )
}
