import React, {useContext, useEffect, useRef, useState} from "react";
import {GlobalContext} from "./_services/global-context";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import Api from "./_services/api_service";
import {Table} from "react-bootstrap";
import Services from "./_services/services";
import axios from "axios";

export default function Checkout() {
    const [global, dispatch] = useContext(GlobalContext)
    const [orderId, setOrderId] = useState(global.order_id);
    const [settings, setSettings] = useState({});
    const [orderDetails, setOrderDetails] = useState([]);
    const [cartSummery, setCartSummery] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [globalPayment, setGlobalPayment] = useState({payment_form: ''});

    const [isCustomerAttached, setCustomerAttached] = useState(false);
    const [iframeContent, setIframeContent] = useState('');

    const iframeRef = useRef(null);
    let apiUrl = Api.getApiURL();


    useEffect(() => {
        if (!global.token || global.token == '' || !global.user || !global.user.id) {
            dispatch({type: 'RETURNING-URL', payload: '/checkout'})
            window.location.assign('/login?rd=/checkout')
        }

        if (global.order_id) {
            handleLoadCartData()
        }

        loadPaymentSettings()

        if (settings['online-payment-processor-name'] && settings['online-payment-processor-name'] === 'global') {
            //if (!globalPayment.payment_form || globalPayment.payment_form.trim() === '') {
            //  loadGlobalPaymentForm()
            //}
        }

    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            // Ensure the message is coming from the expected origin
            // if (event.origin !== 'http://expected-origin.com') {
            //   return;
            // }

            const receivedMessage = event.data;
            console.log('Message received from iframe:', receivedMessage);

            // Example of splitting the received message if it contains a comma-separated string
            /*
            const parts = receivedMessage.split(',');
            console.log('Message parts:', parts);
            */
        }

        window.addEventListener('message', handleMessage);
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        }
    }, []);


    useEffect(() => {
        // SHIPPING-ADDRESS
        // Check to see if customer is attached before redirecting:
        if (!isCustomerAttached) {
            // return;
        }

        dispatch({type: 'REQUESTED-PAGE', payload: 'checkout'})
        if (!global.shipping_address) {
            window.location.assign('/shipping-address');
        }

    }, [isCustomerAttached]);


    // Function to handle messages received from the iframe
    const handleMessageFromIframe = (event) => {
        // Ensure the message is from the expected origin
        if (event.origin !== 'https://iframe-origin.com') { // Replace with the actual origin of the iframe
            return;
        }

        // Handle the received message
        const { type, payload } = event.data;

        switch (type) {
            case 'apiResponse':
                console.log('Received API response from iframe:', payload);
                // Perform actions with the API response data
                break;
            default:
                console.warn('Unknown message type:', type);
        }
    };

    const loadPaymentSettings = () => {
        const qryParams = '?pcl_id=' + global.pcl.id + '&minimal=1&slug=payment-currency,online-payment-processor-name,online-payment-account-id'
        Api.get(`client/settings` + qryParams, (res) => {
            const data = res.data.data
            const settings_list = data
            settings_list.map((setting) => {
                settings[setting.setting] = setting.value
                if (setting.setting == 'waiver-text') {
                    settings['waiver_term_id'] = setting.id
                }
            })

            //
            setSettings({...settings})
            if (settings['online-payment-processor-name'] && settings['online-payment-processor-name'] === 'global') {
                //if (!globalPayment.payment_form || globalPayment.payment_form.trim() === '') {
                loadGlobalPaymentForm()
                //}
            }

        })
    }

    const loadGlobalPaymentForm = () => {
        const params = {
            pcl_id: global.pcl.id,
            order_id: global.order_id,
            amount: 0.1,
            checkout_frame_id: 'checkout_frame_id'
        }
        const qryParams = '?' + new URLSearchParams(params).toString()
        Api.get(`client/global-payments` + qryParams, (res) => {
            const data = res.data.data
            globalPayment['payment_form'] = data.form
            setGlobalPayment({...globalPayment})
        })
    }

    const handleLoadCartData = () => {
        const params = {
            pcl_id: global.pcl.id,
        }

        const qryParams = new URLSearchParams(params).toString()
        Api.get(`client/orders/${orderId}?` + qryParams, (res) => {
            const data = res.data.data
            setOrderDetails(data.cart_details)
            setCartSummery(data.cart_summery)
        }, (errors) => {
            //dispatch({type: "SHOW-ERROR", payload: JSON.stringify(errors)})
        })
    }

    const handleSavePaymentDetails = (payment_response) => {

        const transaction = payment_response.purchase_units[0].payments.captures[0];

        const params = {
            pcl_id: global.pcl.id,
            order_id: global.order_id,
            payment_response: payment_response
        }
        Api.post(`client/paypal-payments`, params, (res) => {
            const data = res.data.data

            // clear local storage order related settings
            dispatch({type: 'ORDER-ID', payload: ''})
            dispatch({type: 'ORDER-TIME', payload: ''})
            dispatch({type: 'CHECKOUT-TIME', payload: ''})
            dispatch({type: 'CART-ITEMS-COUNT', payload: 0})
            dispatch({type: 'SHIPPING-ADDRESS', payload: false})

            setTimeout(() => {
                window.location.assign('/payment-confirmation')
            }, 500);

        }, (errors) => {
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
        })
    }

    const handleTestResponse = () => {
        const payload = {
            CUSTOM_FIELD_NAME: global.pcl.id,
            ORDER_ID: '1717811707O-1361', //global.order_id,
            AMOUNT: 9.99,
            RESULT: '00',
            TIMESTAMP: '',
            DS_TRANS_ID: '343s34rfgtr65',
        }

        Api.post(`client/global-payments`, payload, (res) => {
            const data = res.data.data
        }, (errors) => {
            console.log('errors: ', errors)
        })
    }

    //
    function sendMessageToParent() {
        //const message = 'Hello from iframe';
        //window.parent.postMessage(message, '*'); // '*' can be replaced with the specific origin of the parent window for better security.
    }

    // Client id
    const currency = settings['payment-currency'];
    const client_id = settings['online-payment-account-id'];
    //   currency_code: settings['payment-currency']

    const initialOptions = {
        "client-id": client_id,
        'currency': currency,
        "enable-funding": "card",
        "disable-funding": "paylater,venmo",
        "data-sdk-integration-source": "integrationbuilder_sc",
    };

    /*
        Sandbox Transaction:
        <br>Visa(Success) : 4263970000005262
        <br>Visa(Decline) : 4000120000001154
    */

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='text-center checkout-section'>

                <button onClick={() => loadPaymentSettings()}>Send Message to Parent</button>

                <div className='mt-2'>
                    <Table bordered className='' size={'sm'}>
                        <thead className='cart-td-bg-light-green'>
                        <tr><td colSpan={2} className='text-white fw-bold fs-17x'>Payment Summary</td></tr>
                        </thead>
                        <tbody>
                        {(cartSummery.sub_total && cartSummery.grand_total) &&
                            <>
                                <tr>
                                    <td className='fw-bold w-50'>Sub Total</td>
                                    <td className='text-end price-cell'>
                                        <div className='price-text'>${Number(cartSummery.sub_total).toFixed(2)}</div>
                                    </td>
                                </tr>

                                {(cartSummery.tax1_name) &&
                                    <tr>
                                        <td className='fw-bold w-50'>{cartSummery.tax1_name}</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.tax1_total).toFixed(2)}</div>
                                        </td>
                                    </tr>
                                }

                                {(cartSummery.tax2_name) &&
                                    <tr>
                                        <td className='fw-bold w-50'>{cartSummery.tax2_name}</td>
                                        <td className='text-end price-cell'>
                                            <div
                                                className='price-text'>${Number(cartSummery.tax2_total).toFixed(2)}</div>
                                        </td>
                                    </tr>
                                }

                                <tr>
                                    <td className='fw-bold w-50'>Total</td>
                                    <td className='text-end price-cell'>
                                        <div
                                            className='price-text'>${Number(cartSummery.grand_total).toFixed(2)}</div>
                                    </td>
                                </tr>
                            </>
                        }
                        </tbody>
                    </Table>
                </div>

                {(cartSummery.grand_total > 0 && settings['online-payment-processor-name'] === 'paypal' && settings['online-payment-account-id'] && settings['online-payment-account-id'] !== '') &&
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: Number(cartSummery.grand_total).toFixed(2),
                                        }
                                    }]
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    dispatch({type: "SHOW-SUCCESS", payload: 'Payment processed successfully,'})
                                   handleSavePaymentDetails(details)
                                });
                            }}
                            onError={(err) => {
                                console.error("PayPal Checkout onError", err);
                            }}
                        />
                    </PayPalScriptProvider>
                }

                {(cartSummery.grand_total > 0 && settings['online-payment-processor-name'] === 'global') &&
                    <div className='mt-3'>
                        {/*
                        <button className='btn btn-secondary me-2 mb-2' onClick={handleTestResponse}>Text Response Link</button>
                        <button className='btn btn-secondary mb-2' onClick={loadGlobalPaymentForm}>Get Payment Info</button>
                        */}

                        {/* Global payment form */}
                        <div dangerouslySetInnerHTML={{__html: globalPayment.payment_form}}/>

                        <iframe name="checkout_iframe" className='global-payment-iframe mt-3' style={{minHeight: '579px'}}></iframe>
                    </div>
                }

                {(cartSummery.grand_total === 0 && orderDetails.length > 0) &&
                    <div><h5>Sorry, payment cannot be process with 0 amount.</h5></div>
                }

                {(orderDetails.length > 0 && (!settings['online-payment-account-id'] || settings['online-payment-account-id'].trim() === '')) &&
                    <div>
                        <h4 className='text-warning'>Payment configuration error, contact to the support for further details.</h4>
                    </div>
                }

                {(orderDetails.length === 0) &&
                    <div><h4 className='text-warning'>Cart details not found.</h4></div>
                }

                {/*
                <div ng-show="posview=='checkout'" class="col col-xs-12 text-center" style="border:1px solid rgb(230,230,230); padding-left:30px;padding-right:30px;">
                    <br>
                    <h3 class="color-3">Payment of :${{selAmount}}(CAD), Order#:<?php echo Config::OrderPrefix;?>{{selOrder.id}}</h3>
                    <div ng-if="sandbox==1" style="background:orange;">
                        Sandbox Transaction:
                        <br>Visa(Success) : 4263970000005262
                        <br>Visa(Decline) : 4000120000001154
                    </div>
                    <br>
                    <iframe src="loading.html" id="checkout_frame_id" name="checkout_frame" style="width:100%;height:600px;border:0px;"></iframe>
               </div>
                */}

            </div>
        </div>
    )
}