import React, {useContext, useEffect} from "react";
import logo_image from "../images/playtopia-logo-1.png"
import {GlobalContext} from "./_services/global-context";
import {EmojiSmile} from "react-bootstrap-icons";

export default function ThankYou() {
    const [global, dispatch] = useContext(GlobalContext)

    useEffect(() => {

    }, []);

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='text-center'>
                <div style={{width: '253px'}} className='mb-4 m-auto'>
                    <EmojiSmile className='text-light-green mt-5' style={{fontSize: '77px'}}/>
                </div>

                <div className='mt-2 m-auto'>
                    <h3 className=''>Order Completed!</h3>
                    <h3 className='text-light-green'>Thank you, for submitting your order payment.</h3>
                </div>
            </div>
        </div>
    )
}