import React, {useContext, useEffect, useState} from "react";
import {Container, Nav} from 'react-bootstrap';
import {Cart4} from "react-bootstrap-icons";
import {GlobalContext} from "../_services/global-context";
import logo_image from "../../images/playtopia-logo-1.png";
import CheckoutTimer from "../checkout_timer";

function MobileCart({isMobile}) {
    const [global, dispatch] = useContext(GlobalContext)


    useEffect(() => {

    }, []);

    return (
        <Container>
            <div className='d-flex justify-content-center align-items-center' style={{padding: '12px'}}>
                <div>
                    <div className='fw-bold fs-17x' onClick={(e) => e.preventDefault()}>
                        <CheckoutTimer/>
                    </div>
                </div>
                {/*<div>
                    <div className="d-inline-block" style={{maxHeight: '100px'}}>
                        <a href="/cart" className='pos position-relative'>
                            <span className='fs-19x text-warning'><Cart4/></span>
                            <div className='cart-counter fw-bold'>{global.cart_items_count}</div>
                        </a>
                    </div>
                </div>*/}
            </div>
        </Container>
    );
}

export default MobileCart;