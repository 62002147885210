import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import {
    ArrowDownCircle,
    ArrowUpRightCircleFill, Back, EnvelopeCheckFill, FileEarmarkPost, HouseCheckFill, HouseDoor,
    PersonCircle,
    PersonFillCheck,
    PhoneVibrateFill
} from "react-bootstrap-icons";
import moment from "moment";
import {Table} from "react-bootstrap";
export default function Waivers() {
    const [global, dispatch] = useContext(GlobalContext);
    const [waiver, setWaiver] = useState({});
    const [waivers, setWaivers] = useState([]);

    useEffect(() => {

        handleLoadDetails()

    }, [])

    const handleLoadDetails = () => {
        const qryParams = '?pcl_id=' + global.pcl.id;
        Api.get(`client/waivers` + qryParams, (res) => {
            const data = res.data.data;
            setWaiver((data.length > 0) ? data[0] : {});
            setWaivers(data);
        });
    }

    const handleSelectWaiver = (waiver_id) => {
        console.log('waiver_id: ', waiver_id)
    }

    console.log('waivers: ', waivers)
    return (
        <div className=''>

            {/* Current waiver details */}
            <div>
                <div className='d-flex justify-content-between text-light-green fs-26x fw-bold mb-1'>
                    <div>Current waiver
                        {(waiver.members && waiver.members.length > 0) &&
                            <span className='fs-13x text-dark-light'>
                            (SignDate: {moment(waiver.created_at).format('DD-MM-YYYY HH:mm:ss')})
                        </span>
                        }
                    </div>
                    <div>
                        {(waivers.length === 0) ?
                            <a className='btn btn-primary btn-sm' style={{minWidth: '176px'}}
                               href={`/waiver-sign?rd=${(global.returning_url === 'my-waivers') ? 'my-waivers' : '/my-account'}`}>
                                SIGN WAIVER</a>
                            :
                            <a className='btn btn-success btn-sm' style={{minWidth: '176px'}}
                               href={`/waiver-sign?rd=${(global.returning_url === 'my-waivers') ? 'my-waivers' : '/my-account'}`}>
                                UPDATE WAIVER</a>
                        }
                    </div>
                </div>
                <div className='mt-1 mb-3' style={{borderTop: '2px solid #c5c5c5'}}></div>

                {/* Current waiver */}
                {(waiver.members && waiver.members.length > 0) &&
                    <div className='mb-3'>
                        <div className='profile-list d-flex align-items-center'>
                            <PersonFillCheck className='fs-26x me-2' style={{color: '#5eb388'}}/>
                            <div className='text-dark-light fw-bold me-4'>Name:</div>
                            <div>{`${waiver.members[0].first_name} ${waiver.members[0].last_name}`}</div>
                        </div>

                        <div className='profile-list d-flex align-items-center'>
                            <PhoneVibrateFill className='fs-24x me-2' style={{color: '#5eb388'}}/>
                            <div className='text-dark-light fw-bold me-4'>Contact:</div>
                            <div>{`${waiver.members[0].contact_no}`}</div>
                        </div>

                        <div className='profile-list d-flex align-items-center'>
                            <EnvelopeCheckFill className='fs-21x me-2' style={{color: '#5eb388'}}/>
                            <div className='text-dark-light fw-bold me-4'>Email:</div>
                            <div>{`${waiver.email}`}</div>
                        </div>

                        <div className='d-flex gap-5'>
                            <div className='profile-list d-flex align-items-center'>
                                <Back className='fs-21x me-2' style={{color: '#5eb388'}}/>
                                <div className='text-dark-light fw-bold me-4'>City:</div>
                                <div>{`${waiver.members[0].city}`}</div>
                            </div>
                            <div className='profile-list d-flex align-items-center'>
                                <FileEarmarkPost className='fs-21x me-2' style={{color: '#5eb388'}}/>
                                <div className='text-dark-light fw-bold me-4'>Postal Code:</div>
                                <div>{`${waiver.members[0].postal_code}`}</div>
                            </div>
                        </div>

                        <div className='d-flex gap-5'>
                            <div className='profile-list d-flex align-items-center'>
                                <HouseCheckFill className='fs-21x me-2' style={{color: '#5eb388'}}/>
                                <div className='text-dark-light fw-bold me-4'>Address 1:</div>
                                <div>{(waiver.members[0].address1) ? `${waiver.members[0].address1}` : "---"}</div>
                            </div>
                            <div className='profile-list d-flex align-items-center'>
                                <HouseDoor className='fs-21x me-2' style={{color: '#5eb388'}}/>
                                <div className='text-dark-light fw-bold me-4'>Address 2:</div>
                                <div>{(waiver.members[0].address1) ? `${waiver.members[0].address1}` : "---"}</div>
                            </div>
                        </div>

                        <div className='mt-3 mb-3 fw-bold' style={{borderBottom: '2px solid #c5c5c5'}}>Members</div>
                        <Table className='table table-sm table-bordered'>
                            <thead className='thead-light'>
                            <tr className=''>
                                <th className=''>First Name</th>
                                <th className=''>Last Name</th>
                                <th className=''>Birthday</th>
                            </tr>
                            </thead>
                            <tbody>
                            {waiver.members.map((member, index) => {
                                if (index === 0) return null;
                                let birthday = '';
                                if (member.dob_day) birthday = member.dob_day
                                if (member.dob_month) {
                                    birthday += (birthday) ? '-' + member.dob_month : member.dob_month;
                                }

                                if (member.dob_year) {
                                    birthday += (birthday) ? '-' + member.dob_year : member.dob_year;
                                }

                                return (
                                    <tr>
                                        <td>{member.first_name}</td>
                                        <td>{member.last_name}</td>
                                        <td>{birthday}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>

                        <div className='mt-5'>
                            {/*<a href='#' className='btn btn-warning me-3'>View/Download Waiver</a>*/}
                            <a href={`/waiver-sign?rd=${(global.returning_url === 'my-waivers') ? 'my-waivers' : '/my-account'}`}
                               className='btn btn-success'>Update Waiver</a>
                        </div>

                        <div className='mt-3 mb-3' style={{borderTop: '2px solid #c5c5c5'}}></div>
                    </div>
                }
            </div>

            {/* Previous waivers list */}
            <div className='fw-bold fs-22x mb-2 text-dark-light mt-4'>Previously Signed Waivers</div>
            <div className='d-flex align-items-center data-list mb-1 gap-3'>
                <div className='fw-bold' style={{width: '70px'}}>#</div>
                <div className='fw-bold flex-1'>Waiver Ref#</div>
                <div className='fw-bold flex-1'>Sign Date/Time</div>
                {/*<div className='fw-bold'>Download</div>*/}
            </div>
            {waivers.map((waiver, index) => {
                if (index == 0) return null;
                return (
                    <div className='d-flex align-items-center data-list mb-1 gap-3'>
                        <div className='' style={{width: '70px'}}>{index + 1}</div>
                        <div className='flex-1'>W{String(waiver.id).padStart(3, '0')}</div>
                        <div className='flex-1'><span
                            className=''>{moment(waiver.created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
                        </div>
                        {/*<div className='fs-15x'>*/}
                        {/*    <a href='#' className='link-success text-decoration-none'*/}
                        {/*       onClick={() => handleSelectWaiver(waiver.id)}>*/}
                        {/*        View/Download <ArrowDownCircle className='fs-19x text-light-green cursor-pointer'/>*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                )
            })}
        </div>
    )
}