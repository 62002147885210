import axios from 'axios';
import {GlobalContext} from "./global-context";

let activeRequests = 0;

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = 'Bearer ' + token;

    activeRequests++;
    setRequestLoader(true);
    return config;
}, function (error) {
    activeRequests--;
    if (activeRequests === 0) {
        setRequestLoader(false);
    }
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    activeRequests--;
    if (activeRequests === 0) {
        setRequestLoader(false);
    }
    return response;
}, function (error) {
    activeRequests--;
    if (activeRequests === 0) {
        setRequestLoader(false);
    }
    return Promise.reject(error);
});

function setRequestLoader(show) {
    const element = document.getElementById('request_loader');
    if (element) {
        if (show) {
            element.classList.add('loader-show');
        } else {
            element.classList.remove('loader-show');
        }
    }
}


export default class Api {
    static apiBaseUrl = false;
    static contextType = GlobalContext;
    
    static getApiURL() {
        let api_url = "https://api2.onelinkpos2.com/api";
        //If api url is defined in .env file just use that one.
        if (process.env.REACT_APP_API_URL != undefined) {
            api_url = process.env.REACT_APP_API_URL;
        }

        this.apiBaseUrl = api_url + "/";
        return this.apiBaseUrl;

    }

    static get(endpoint, callback_success, callback_error) {
        let apiUrl = this.getApiURL();
        axios.get(apiUrl + endpoint).then((response) => {
                if (callback_success) {
                    callback_success(response);
                }
            }).catch(error => {
                console.log("Data:", error.response);
                if (error.response && error.response.data) {
                    if (error.response.data.message == "Unauthenticated."){
                        localStorage.setItem('token', '');
                        localStorage.setItem('user', JSON.stringify({}));

                        localStorage.removeItem("user_pcl");
                        localStorage.removeItem("user");
                        localStorage.removeItem("token");
                        // window.location.href = "/logout";
                    }
                    // window.location.href = "/auth/logout";
                }

                let errorObj = error;
                if (error.response && error.response.data) {
                    if (error.response.data.code == 403) {
                        errorObj = "Unauthorized access";
                    }else if (error.response.data.data && (error.response.data.data.length > 0 || Object.keys(error.response.data.data).length > 0)) {
                        errorObj = error.response.data.data
                    } else if (error.response.data.message) {
                        errorObj = error.response.data.message
                    }
                }
                if (callback_error) {
                    callback_error(errorObj);
                } else {
                    var error_message = "";
                    if (error.response && error.response.data && error.response.data.errorCode) {
                        if (error.response.data.displayMessage != undefined && error.response.data.displayMessage != "") {
                            error_message = error.response.data.displayMessage;
                        } else {
                            error_message = "Unknown error, error code: " + error.response.data.errorCode;
                        }
                    }
                    // console.log("Error calling:" + apiUrl + endpoint, error_message, error);
                }
            })
    }

    // {headers: {"Content-Type": "multipart/form-data"}}
    static post(endpoint, payload, callback_success, callback_error) {
        let apiUrl = this.getApiURL();
        axios.post(apiUrl + endpoint, payload)
            .then(response => {
                if (callback_success != undefined) {
                    callback_success(response);
                }
            }).catch(error => {
            //console.log("Error:" + apiUrl + endpoint, error);
            let errorObj = error;
            if (error.response && error.response.data) {
                if (error.response.data.code == 403) {
                    errorObj = "Unauthorized access";
                }else if (error.response.data.message == "Unauthenticated.") {
                    errorObj = error.response.data.message;
                }else {
                    if (error.response.data.errorCode && error.response.data.errorCode == "invalid_token") {
                        localStorage.setItem('token', '');
                        localStorage.setItem('user', JSON.stringify({}));
                        window.location.href = "/login";
                    } else if (error.response.data.data) {
                        if (error.response.data.data != false) {
                            errorObj = error.response.data.data;
                        } else if (error.response.data.message) {
                            errorObj = error.response.data.message;
                        }
                    }
                }
            }

            if (callback_error != undefined) {
                callback_error(errorObj);
            } else {
                var error_message = "";
                if (error.response != undefined && error.response.data != undefined && error.response.data.errorCode != undefined) {
                    if (error.response.data.displayMessage != undefined && error.response.data.displayMessage != "") {
                        error_message = error.response.data.displayMessage;
                    } else {
                        error_message = "Unknown error, error code: " + error.response.data.errorCode;
                    }
                }
                // console.log("Error calling:" + apiUrl + endpoint, error_message, error);
            }
        });
    }

    static put(endpoint, payload, callback_success, callback_error) {
        let apiUrl = this.getApiURL();
        axios.put(apiUrl + endpoint, payload).then(response => {
                if (callback_success != undefined) {
                    callback_success(response);
                }
            }).catch(error => {
                //console.log("Error:" + apiUrl + endpoint, error);
                let errorObj = error;
                if (error.response && error.response.data) {
                    if (error.response.data.code == 403) {
                        errorObj = "Unauthorized access";
                    }if (error.response.data.errorCode && error.response.data.errorCode == "invalid_token") {
                        window.location.href = "/login";
                    } else if (error.response.data.data) {
                        if (error.response.data.data) {
                            errorObj = error.response.data.data;
                        } else if (error.response.data.message) {
                            errorObj = error.response.data.message;
                        }
                    }
                }
                if (callback_error) {
                    callback_error(errorObj);
                } else {
                    var error_message = "";
                    if (error.response && error.response.data && error.response.data.errorCode) {
                        if (error.response.data.displayMessage != undefined && error.response.data.displayMessage != "") {
                            error_message = error.response.data.displayMessage;
                        } else {
                            error_message = "Unknown error, error code: " + error.response.data.errorCode;
                        }
                    }
                    console.log("Error calling:" + apiUrl + endpoint, error_message, error);
                }
            });
    }

    static delete(endpoint, payload, callback_success, callback_error) {
        let apiUrl = this.getApiURL();
        axios.delete(apiUrl + endpoint, payload).then(response => {
                if (callback_success != undefined) {
                    callback_success(response);
                }
            }).catch(error => {
            //console.log("Error:" + apiUrl + endpoint, error);
            let errorObj = error;
            if (error.response != undefined && error.response.data != undefined) {
                if (error.response.data.errorCode != undefined && error.response.data.errorCode == "invalid_token") {
                    window.location.href = "/login";
                } else if (error.response.data.data != undefined) {
                    if (error.response.data.data != false) {
                        errorObj = error.response.data.data;
                    } else if (error.response.data.message != undefined) {
                        errorObj = error.response.data.message;
                    }
                }
            }
            if (callback_error != undefined) {
                callback_error(errorObj);
            } else {
                var error_message = "";
                if (error.response != undefined && error.response.data != undefined && error.response.data.errorCode != undefined) {
                    if (error.response.data.displayMessage != undefined && error.response.data.displayMessage != "") {
                        error_message = error.response.data.displayMessage;
                    } else {
                        error_message = "Unknown error, error code: " + error.response.data.errorCode;
                    }
                }
                // console.log("Error calling:" + apiUrl + endpoint, error_message, error);
            }
        });
    }

}    
