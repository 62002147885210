import React, {useContext, useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import moment from "moment";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import {ArrowRight, DashLg, ExclamationCircle, Pencil, PlusCircle} from "react-bootstrap-icons";
import logo_image from "../../images/playtopia-logo-1.png";
import Services from "../_services/services";
import {cloneDeep} from "lodash";
import URL from "../_services/routes";
import axios from "axios";

export default function WaiverSign() {
    const [global, dispatch] = useContext(GlobalContext)
    const [waiver, setWaiver] = useState({});
    const [customerId, setCustomerId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [processing, setProcessing] = useState(false);


    // ---------------------------------------------------- //
    const [errors, setErrors] = useState({});
    const [waivers, setWaivers] = useState([])
    const [addWaiver, setAddWaiver] = useState(false)
    const [updateWaiver, setUpdateWaiver] = useState(false)
    const [waiverSettings, setWaiverSettings] = useState({})
    const [memError, setMemError] = useState({})
    const [selectedMember, setSelectedMember] = useState({})
    const [selectedMemberIndex, setSelectedMemberIndex] = useState(null)

    const [rsvp, setRsvp] = useState({});
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [defaultSelected, setDefaultSelected] = useState({
        country_id: '', selected_country: '',
        state_id: '', selected_state: '',
    })

    const [waiverModal, setWaiverModal] = useState(false)
    const [memberRemove, setMemberRemove] = useState(null)
    const [waiverConfirmationAlert, setWaiverConfirmationAlert] = useState(false)

    const log_user = global.user ?? {};
    const default_member = {
        first_name: log_user.first_name ?? '', last_name: log_user.last_name ?? '', contact_no: log_user.contact_no ?? '',
        dob_day: '', dob_month: '', dob_year: '', country_id: '', state_id: '', city: '', postal_code: '', address1: '', address2: '',
        signature_type: 'image', text_signature: '', image_signature: '', status: 1,
        address_same_as: false, states: [], selected_country: {}, selected_state: {}
    };
    const months_list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const [waiverMembers, setWaiverMembers] = useState([default_member]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState(months_list);
    const [selectedMonth, setSelectedMonth] = useState('');

    const formRef = useRef(null);

    const accessType = URL.accessType();
    const apiUrl = Api.getApiURL();

    const waiver_qp = URL.urlQueryParams('waiver');
    const rsvp_id_qry = URL.urlQueryParams('rsvp_id');
    const [rsvpId, setRsvpId] = useState(rsvp_id_qry || '');

    useEffect(() => {

        const currentYear = moment().year(); // Get the current year
        const startYear = currentYear;
        const endYear = currentYear - 120

        // set list of years
        const years_list = []
        for (let i = startYear; i >= endYear; i--) {
            years_list.push(i)
        }
        setYears(years_list)

        if (global.user && global.user.id) {
            waiver['email'] = global.user.email
            setWaiver({...waiver})
        }

        // dispatch({type: 'RETURNING-URL', payload: '/kiosk'})
        loadWaiverSettings()
        setMonthDays(selectedMonth)

        if (countries.length === 0) {
            loadCountries()
        }
        // loadStates()

        //  handleVerifyEmail()
    }, []);

    useEffect(() => {
        console.log(global.user)
        if (global.token && global.token.trim() !== '' && global.user && global.user.id && countries.length > 0) {
            console.log('global.user.id: ', global.user.id)
            setCustomerId(global.user.id)
            if (!waiver_qp || waiver_qp !== 'new') {
                setUpdateWaiver(true)
                console.log('defaultSelected:', defaultSelected)
                handleLoadWaiverDetail(global.user.id)
            }else {
                setAddWaiver(true)
            }
        }

        if (!global.token && rsvpId && countries.length > 0) {
            // setAddWaiver(true)
            console.log('defaultSelected:', defaultSelected)
            handleLoadRsvpsDetails(rsvpId)
        }

        if (!rsvpId && waiver_qp === 'new') {
            waiverMembers[0].country_id =defaultSelected.country_id;
            waiverMembers[0].selected_country = defaultSelected.selected_country;
            waiverMembers[0]['state_id'] = defaultSelected.state_id;
            waiverMembers[0]['selected_state'] = defaultSelected.selected_state;
            waiverMembers[0]['states'] = states
            setWaiverMembers([...waiverMembers]);
        }


    }, [countries]);

    const handleVerifyEmail = (e) => {
        if (e) e.preventDefault();
        waiver['pcl_id'] = global.pcl.id
        waiver['check'] = 'customer-email'

        let qryParams = new URLSearchParams(waiver).toString()
        Api.get(`users/verify?` + qryParams, (res) => {
            const data = res.data.data
            if (data) {
                console.log('IF INN')
                dispatch({type: "USER", payload: data})
                Services.redirectTo('/login', global.source, 'rd=/waiver-sign&waiver=new')
            }else {
                setAddWaiver(true)
            }

        }, (errors) => {
            if (!waiver.email || waiver.email === '' || waiver.email === ' ') {
                setErrorMessage('Email required please must add email to start waiver sign')
                return false;
            }else if (errors) {
                const errors_list = Services.getErrorList(errors)
                setErrorMessage(errors_list)
            }
        })
    }

    const handleErrors = (errors, message) => {
        setErrors(errors);
        dispatch({type: "SHOW-ERROR", payload: message})
    }

    const handleChange = (fieldName, val) => {
        waiver[fieldName] = val;
        setWaiver({...waiver})
    }

    const loadWaiverSettings = () => {
        const qryParams = '?pcl_id=' + global.pcl.id + '&minimal=1&setting_type=waiver'
        Api.get(`client/settings` + qryParams, (res) => {
            const settings = res.data.data
            // const settings = data.list
            settings.map((setting) => {
                waiverSettings[setting.setting] = setting.value
                if (setting.setting == 'waiver-text') {
                    waiverSettings['waiver_term_id'] = setting.waiver_term_id
                }
            })

            setWaiverSettings(waiverSettings)
        })
    }

    const loadCountries1 = () => {
        Api.get(`admin/countries?pcl_id=${global.pcl.id}`, res => {
            const countries_arr = res.data.data;
            let options = []
            countries_arr.forEach(country => {
                let option = {value: country.id, label: country.name}
                options.push(option)

                /*
                if (!waiverMembers[0].country_id && global.location && global.location.country_id && global.location.country_id == country.id) {

                    waiverMembers[0].country_id = country.id
                    waiverMembers[0].selected_country = option

                    handleCountryChange(country.id).then((states_arr) => {
                        waiverMembers[0]['states'] = states_arr
                        const selected_state = states_arr.find(sObj => sObj.value == global.location.state_id)
                        if (selected_state) {
                            waiverMembers[0]['state_id'] = selected_state.value
                            waiverMembers[0]['selected_state'] = selected_state
                            setWaiverMembers([...waiverMembers])
                        }
                    }).catch((error) => {
                        // Handle the error
                        // console.error(error);
                    });

                    setWaiverMembers([...waiverMembers])
                }
                */
            })

            setCountries(options);
        }, error => {
            dispatch({type: "SHOW-ERROR", payload: error.message});
        });
    }

    const loadCountries = async () => {
        //
        const endpoint = apiUrl + `admin/countries?pcl_id=${global.pcl.id}`;
        try {
            const res = await axios.get(endpoint);
            const countries_arr = res.data.data;
            let options = []
            countries_arr.forEach(country => {
                let option = {value: country.id, label: country.name}
                if (country.id == global.location.country_id) {
                    defaultSelected['country_id'] = country.id;
                    defaultSelected['selected_country'] = option;
                    setDefaultSelected({...defaultSelected})
                }
                options.push(option)
            });

            // Handle default country and state
            if (!waiverMembers[0].country_id && global.location && global.location.country_id) {

                const country_id = global.location.country_id;
                try {
                    const endpoint = apiUrl + `admin/states/${country_id}?pcl_id=${global.pcl.id}`;
                    const res = await axios.get(endpoint);

                    let options = [];
                    const states_arr = res.data.data;
                    states_arr.forEach(state => {
                        let option = {value: state.id, label: state.name}
                        if (state.id == global.location.state_id) {
                            defaultSelected['state_id'] = state.id;
                            defaultSelected['selected_state'] = option;
                            setDefaultSelected({...defaultSelected});
                        }
                        options.push(option)
                    });

                    setStates([...options]);
                } catch (error) {
                    // console.error('There was an error with your request: ', );
                    // dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)});
                }
            }

            setCountries(options);
        } catch(error) {
            // console.error('There was an error with your request: ', );
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(error)});
        }
    }

    function handleLoadRsvpsDetails(rsvpId) {
        const qryParams = `?pcl_id=${global.pcl.id}&rsvp_id=${rsvpId}`
        Api.get(`client/rsvps` + qryParams, (res) => {
            const data = res.data.data
            waiver.email = data.email;
            setRsvp(data)
            setWaiver({...waiver});

            const members = [];
            const waiver_member = {
                first_name: '', last_name: '', contact_no: '', dob_day: '', dob_month: '', dob_year: '',
                country_id: '', state_id: '', city: '', postal_code: '', address1: '',address2: '',
                signature_type: 'image', text_signature: '', image_signature: '',status: 1,
                address_same_as: true, states: [], selected_country: {}, selected_state: {}
            }

            data.members.map((member, index) => {
                if (member.status === 2) {
                    const s_member = cloneDeep(waiver_member)
                    console.log('member: ', index, member)
                    s_member.first_name = member.first_name;
                    s_member.last_name = member.last_name;
                    s_member.contact_no = data.contact_no;
                    s_member.address_same_as = (index !== 0);

                    s_member['country_id'] = defaultSelected.country_id;
                    s_member['selected_country'] = defaultSelected.selected_country
                    s_member['states'] = states
                    s_member['state_id'] = defaultSelected.state_id
                    s_member['selected_state'] = defaultSelected.selected_state

                    members.push(s_member)
                }
            });

            setWaiverMembers([...members])
        })
    }

    const handleLoadWaiverDetail = () => {
        const params = {
            pcl_id: global.pcl.id,
            waiver_type: 'latest',
        }

        const qryParams = new URLSearchParams(params).toString();
        Api.get(`client/waivers?` + qryParams, (res) => {
            const data = res.data.data
            const waiver_members = data.members
            let states_arr = [];
            waiver_members.map((member, index) => {
                const country = countries.find(obj => obj.value === member.country_id)
                if (country) {
                    waiver_members[index]['selected_country'] = country

                    if (index === 0 || member.state_id !== waiver_members[0].state_id) {
                        handleCountryChange(member.country_id).then((options) => {
                            const state = options.find(obj => obj.value == member.state_id)
                            if (state) {
                                waiver_members[index]['selected_state'] = state
                            }

                            console.log('handleCountryChange')
                            states_arr = options
                            setStates([...options])
                            waiver_members[index]['states'] = options
                            //setWaiverMembers([...waiver_members])
                        }).catch((error) => {
                            // Handle the error
                            console.error(error);
                        });

                    } else if (member.state_id == waiver_members[0].state_id) {
                        const sign_states = (waiver_members[0] && waiver_members[0].states) || [];
                        console.log('states_arr : ', states_arr)
                        console.log('waiver_members[0]: ', waiver_members[0].states)
                        const state = sign_states.find(obj => obj.value == member.state_id)
                        if (state) {
                            waiver_members[index]['selected_state'] = state
                        }

                        waiver_members[index]['states'] = sign_states
                        //setWaiverMembers([...waiver_members])
                    }
                }
            })

            setWaiverMembers([...waiver_members])
        })
    }

    const handleMemberChange = (e, index, fieldName = null) => {
        console.log('index: ', index, fieldName)

        const waiver_members = cloneDeep(waiverMembers)

        if (fieldName == null) {
            const {type, name, value} = e.target
            console.log(type + ': ' + name + ': '+ value)

            const signature_type = 'signature_type_' + index
            if (type == 'checkbox') {
                waiver_members[index][name] = e.target.checked
            } else if (name == signature_type) {
                waiver_members[index]['signature_type'] = value
            } else {
                waiver_members[index][name] = value
            }

            if (name == 'dob_month') {
                setSelectedMonth(value)
            }
        }
        else {
            const val = e
            if (fieldName == 'state_id') {
                // setSelectedState(val)
                waiver_members[index]['state_id'] = val.value
                waiver_members[index]['selected_state'] = val
            }

            if (fieldName == 'country_id') {
                //setSelectedState(val)
                waiver_members[index]['state_id'] = ''
                waiver_members[index]['selected_state'] = {}
                waiver_members[index]['country_id'] = val.value
                waiver_members[index]['selected_country'] = val

                handleCountryChange(val.value).then((options) => {
                    console.log('states_arr: ', options)
                    waiver_members[index]['states'] = options
                    setWaiverMembers([...waiver_members])
                }).catch((error) => {
                    // Handle the error
                    console.error(error);
                });
            }
        }

        setWaiverMembers([...waiver_members])
    }

    const handleAddWaiverMember = () => {
        const waiver_member = {
            first_name: '', last_name: '', contact_no: '', dob_day: '', dob_month: '', dob_year: '',
            country_id: '', state_id: '', city: '', postal_code: '', address1: '',address2: '',
            signature_type: 'image', text_signature: '', image_signature: '',status: 1,
            address_same_as: true, states: [], selected_country: {}, selected_state: {}
        }

        waiver_member['contact_no'] = waiverMembers[0]['contact_no']
        waiver_member['city'] = waiverMembers[0]['city']
        waiver_member['postal_code'] = waiverMembers[0]['postal_code']
        waiver_member['address1'] = waiverMembers[0]['address1']
        waiver_member['address2'] = waiverMembers[0]['address2']
        waiver_member['country_id'] = waiverMembers[0]['country_id']
        waiver_member['state_id'] = waiverMembers[0]['state_id']
        waiver_member['states'] = waiverMembers[0]['states']
        waiver_member['selected_country'] = waiverMembers[0]['selected_country']
        waiver_member['selected_state'] = waiverMembers[0]['selected_state']

        waiverMembers.push(waiver_member)
        setSelectedMemberIndex(waiverMembers.length-1)
        setWaiverMembers([...waiverMembers])
    }

    const handleRemoveWaiverMember = (index) => {
        setMemberRemove(null)
        waiverMembers.splice(index, 1)
        setWaiverMembers([...waiverMembers])
    }

    const handleFormSubmit = (e, submit_type = 'add_submit') => {
        if (e) e.preventDefault();

        waiver['submit_type'] = submit_type;
        setErrors({});
        setWaiver({...waiver});
        handleSubmitWaiver();
    }

    const handleValidateWaiverMember = (e) => {
        if (e) e.preventDefault()

        const mData = waiverMembers[selectedMemberIndex] || {}
        mData['pcl_id'] = global.pcl.id
        mData['request_type'] = 'validate-member'

        Api.post(`client/waivers/validate`, mData, (res) => {
            const response = res.data.data
            setErrors({})
            setSelectedMemberIndex(null)

        }, (res_errors) => {
            if(typeof res_errors === 'string') {
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(res_errors)})
            }else {
                const member_errors = [];
                waiverMembers.map((member, index) => {
                    if (index == selectedMemberIndex) {
                        member_errors.push(res_errors[0] || {})
                    }else {
                        member_errors.push({})
                    }
                })
                errors['members_errors'] = member_errors
                setErrors({...errors})
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(errors)})
            }

            setTimeout(() => {
                const invalidFeedback = document.querySelector('.is-invalid');
                if (invalidFeedback) {
                    invalidFeedback.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
        });
    }

    const handleValidateWaiverForm = (e) => {
        if (e) e.preventDefault()

        const formData = new FormData()
        waiver['customer_id'] = customerId
        waiver['pcl_id'] = global.pcl.id

        const error = {members_errors: []}
        let error_found = false;
        for (let index = 0; index < waiverMembers.length; index++) {
            const member = waiverMembers[index];

            if (waiverSettings.year) {
                const dob_day = waiverMembers[index]['dob_day'] || 1
                const dob_month = waiverMembers[index]['dob_month'] || 1;
                const dob_year = waiverMembers[index]['dob_year']

                const memberAge = calculateAge(dob_year, dob_month, dob_day)
                if (index == 0 || (memberAge >= 18 && member.first_name != '')) {
                    if (member.signature_type == 'image') {
                        waiverMembers[index]['text_signature'] = '';

                        const is_valid = handleCheckSignature(index)
                        if (!is_valid) {
                            error_found = true;
                            error.members_errors[index] = {signature: `Please draw valid signature for ${member.first_name +' '+ member.last_name}.`}
                        }

                        const draw_signature = document.getElementById('draw_signature_' + index)
                        if (draw_signature != null && draw_signature != undefined) {
                            const signature_img = draw_signature.toDataURL('image/png');
                            waiverMembers[index]['image_signature'] = signature_img;
                        }
                    } else {
                        waiverMembers[index]['image_signature'] = '';
                    }
                }
            }
        }

        if (error_found) {
            setErrors(error)
            return false;
        }

        setWaiverMembers([...waiverMembers])

        const req_members = handleSetRequestMember(waiverMembers)
        console.log('req_members: ', req_members)
        waiver['members'] = req_members
        waiver['submit_type'] = 'validate'
        Api.post(`client/waivers/validate`, waiver, (res) => {
            const response = res.data.data
            setErrors({})
            setWaiverConfirmationAlert(true)

        }, (res_errors) => {
            if(typeof res_errors === 'string') {
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(res_errors)})
            }else {
                setErrors(res_errors)
                dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(res_errors)})
            }

            setTimeout(() => {
                const invalidFeedback = document.querySelector('.is-invalid');
                if (invalidFeedback) {
                    invalidFeedback.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 500);
        });
    }

    const handleSubmitWaiver = (e) => {
        if (e) e.preventDefault()

        const formData = new FormData()
        waiver['customer_id'] = customerId
        waiver['pcl_id'] = global.pcl.id
        if (rsvp_id_qry) waiver['rsvp_id'] = rsvp_id_qry;

        const error = {members_errors: []}
        let error_found = false;
        for (let index = 0; index < waiverMembers.length; index++) {
            const member = waiverMembers[index];

            if (waiverSettings.year) {
                const dob_day = waiverMembers[index]['dob_day'] || 1
                const dob_month = waiverMembers[index]['dob_month'] || 1;
                const dob_year = waiverMembers[index]['dob_year']

                const memberAge = calculateAge(dob_year, dob_month, dob_day)
                if (index == 0 || (memberAge >= 18 && member.first_name != '')) {
                    if (member.signature_type == 'image') {
                        waiverMembers[index]['text_signature'] = '';

                        const is_valid = handleCheckSignature(index)
                        if (!is_valid) {
                            error_found = true;
                            error.members_errors[index] = {signature: `Please draw valid signature for ${member.first_name +' '+ member.last_name}.`}
                        }

                        const draw_signature = document.getElementById('draw_signature_' + index)
                        if (draw_signature != null && draw_signature != undefined) {
                            const signature_img = draw_signature.toDataURL('image/png');
                            waiverMembers[index]['image_signature'] = signature_img;
                        }
                    } else {
                        waiverMembers[index]['image_signature'] = '';
                    }
                }
            }
        }

        if (error_found) {
            setErrors(error)
            return false;
        }

        setWaiverMembers([...waiverMembers])
        const req_members = handleSetRequestMember(waiverMembers)
        waiver['members'] = req_members
        Api.post(`client/waivers`, waiver, (res) => {
            const response = res.data.data

            dispatch({type: "SHOW-SUCCESS", payload: 'Your waiver signed successfully!'})
            setTimeout(() => {
                if (accessType === 'kiosk'){
                    Services.redirectTo('/', global.source, 'response=1')
                }else {
                   Services.redirectTo(global.returning_url, global.source, 'response=1')
                }
            }, 1000);

        }, (err) => {
            console.log('err---------------------->: ', err)
            setErrors(err)
            dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(err)})

            // if(typeof errors === 'string') {
            //     dispatch({type: "SHOW-ERROR", payload: Services.getErrorList(err)})
            // }
        });
    }

    const handleCancelMember = (e) => {
        console.log(selectedMemberIndex, selectedMember)
        waiverMembers[selectedMemberIndex] = selectedMember
        setWaiverMembers([...waiverMembers])
        setSelectedMember({})
        setSelectedMemberIndex(null)
    }

    // Load states/provinces list on country select
    function handleCountryChange(country_id) {
        return new Promise((resolve, reject) => {
            Api.get(`admin/states/${country_id}?pcl_id=${global.pcl.id}`, res => {
                let options = [];
                const states_arr = res.data.data;
                states_arr.forEach(state => {
                    let option = {value: state.id, label: state.name};
                    options.push(option);
                });
                resolve(options);
            }, error => {
                dispatch({type: "SHOW-ERROR", payload: error.message});
                reject(error.message); // Reject the promise with the error message
            });
        });
    }

    async function fetchCountryStates(country_id) {
        try {
            const response = await handleCountryChange(country_id);
            // Do something with the response
            console.log(response);
            // Return the response
            return response;
        } catch (error) {
            // Handle errors
            console.error(error);
            // Return null or throw the error
            return null; // or throw error;
        }
    }

    const handleClearSignature = (index) => {
        const draw_signature = document.getElementById('draw_signature_' + index)
        if (draw_signature != null && draw_signature != undefined) {
            const context = draw_signature.getContext('2d');
            context.clearRect(0, 0, draw_signature.width, draw_signature.height);
        }

        waiverMembers[index]['image_signature'] = ''
        setWaiverMembers([...waiverMembers])
    }

    const handleResetWaiverMembers = () => {
        const member = {
            first_name: '', last_name: '', contact_no: '', dob_day: '', dob_month: '', dob_year: '',
            country_id: '', state_id: '', city: '', postal_code: '', address1: '',address2: '',
            signature_type: 'image', text_signature: '', image_signature: '',status: 1,
            address_same_as: false, states: [], selected_country: {}, selected_state: {}
        }

        setWaiverMembers([member])
    }

    const setMonthDays = (month, year = moment().year()) => {
        const monthDays = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
        // set list of days of month
        let days_list = [];
        for (let i = 1; i <= monthDays; i++) {
            days_list.push(i);
        }
        return days_list;
    }

    const calculateAge = (year = null, month = 1, day = 1) => {
        let age = 1;
        if (year) {
            const today = moment();
            month = (month) ? month : 0;  // January is 0
            day = (day) ? day : 1;
            const dateOfBirth = moment({ year, month, day });
            age = today.diff(dateOfBirth, 'years');
        }
        return age;
    }


    const differenceBetweenDates = (startDate, endDate = null) => {
        startDate = new Date(startDate);
        if (endDate == null) {
            endDate = new Date();
        }
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();
        const differenceInMonths = (endYear - startYear) * 12 + (endMonth - startMonth);
        return differenceInMonths
    }

    const handleCancel = () => {
        setWaiver({})
        setErrors({})

        setCustomerId(null)
        setAddWaiver(false)
        setUpdateWaiver(false)
    }

    const handleEditMember = (e, index) => {
        e.preventDefault();

        if (selectedMemberIndex) {
            alert('One user is already in edit, Save or Cancel it first.')
            /*const u_confirm = window.confirm('One user is already in edit, Save or Cancel it first.')
            if (u_confirm) {
                setSelectedMember(waiverMembers[index])
                setSelectedMemberIndex(index)
            }*/
        } else {
            setSelectedMember(waiverMembers[index])
            setSelectedMemberIndex(index)
        }
    }

    const handleRegister = (e) => {
        e.preventDefault();
        waiver['pcl_id'] = global.pcl.id
        setProcessing(true)

        Api.post('users/create', waiver, (res) => {
            const data = res.data.data;
            dispatch({type: "SHOW-SUCCESS", payload: 'Your account created successfully!'})

            setTimeout(() => {
                Services.logoutAndRedirect('/kiosk', global.source)
            }, 3000)

        }, (error) => {
            setProcessing(false)
            setErrorMessage(JSON.stringify(error));
            dispatch({type: "SHOW-ERROR", payload: JSON.stringify(error)})
        });
    }

    const handleCheckSignature = (index = 0) => {
        const draw_signature = document.getElementById(`draw_signature_${index}`)
        if (draw_signature != null && draw_signature != undefined) {
            const context = draw_signature.getContext('2d');
            const imageData = context.getImageData(0, 0, draw_signature.width, draw_signature.height).data;

            let isEmpty = true;
            let nonTransparentPixelCount = 0;
            for (let i = 0; i < imageData.length; i += 4) {
                // Check if any pixel is not transparent
                if (imageData[i + 3] !== 0) {
                    //isEmpty = false;
                    nonTransparentPixelCount++;
                    //break;
                }
            }
            return nonTransparentPixelCount >= 200;
        }
    }

    const handleSetRequestMember = (waiver_members) => {
        let res_members = [];
        waiver_members.map((member, index) => {
            delete member['selected_country'];
            delete member['selected_state'];
            delete member['states'];
            res_members.push(member)
        });

        return res_members;
    }

    const handleCancelWaiver = () => {
        handleCancel()
        handleResetWaiverMembers()
        Services.redirectTo(global.returning_url, global.source)
    }


    // React select style
    const targetHeight = 31
    const selectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 'initial',
        }),
        valueContainer: (base) => ({
            ...base,
            height: `${targetHeight - 1 - 1}px`,
            padding: '0 8px',
        }),
        clearIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            padding: `${(targetHeight - 20 - 1 - 1) / 2}px`,
        }),
    }

    let m_index = 0;
    let m_error = {};
    let s_member = {};

    // console.log('errors:', errors)
    // console.log('defaultSelected:', defaultSelected)
    return (
        <div className=''>
            <div className=''>

                {(addWaiver || updateWaiver) &&
                    <Button type='button' variant='danger' size="md" className='text-white float-end me-2'
                            onClick={() => handleCancelWaiver()}>
                        Cancel Waiver</Button>
                }

                <div>
                    <div style={{width: '133px'}} className='mb-2 m-auto'>
                        <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                    </div>
                </div>

                <h3 className='text-center mt-2 fw-bold'>{(addWaiver || updateWaiver) ? 'Enter waiver detail!' : 'Enter your email to sign a waiver!'}</h3>
                <div className='text-center mt-2 '><span className='fs-21x fw-bold'>{waiver.email}</span></div>
                {(!addWaiver && !updateWaiver) &&
                    <div className='d-flex justify-content-center'>
                        <div className='flex-1' style={{maxWidth: '500px'}}>
                            <Form onSubmit={(e) => handleVerifyEmail(e)}>
                                <Form.Group className="mb-3 text-start mt-4" controlId="button_text">
                                    <Form.Label className='req-label'>Enter Yor Email</Form.Label>
                                    <Form.Control type="email" placeholder='---'
                                                  defaultValue={waiver.email}
                                                  onChange={(e) => handleChange('email', e.target.value)}
                                                  required/>
                                </Form.Group>

                                {(errorMessage) &&
                                    <Alert variant='warning' onClose={() => setErrorMessage('')}
                                           dismissible>
                                        {errorMessage}
                                    </Alert>
                                }


                                <Button type='submit' variant='primary' size="md" className='float-end'>
                                    Proceed <ArrowRight/>
                                </Button>

                                <Button type='button' variant='warning' size="md" className='text-white float-end me-2'
                                        onClick={() => handleCancelWaiver()}>
                                    Cancel Waiver</Button>
                            </Form>
                        </div>
                    </div>
                }

                {(addWaiver || updateWaiver) &&
                    <Form autoComplete="off"  ref={formRef} onSubmit={(e) => handleSubmitWaiver(e)} encType="multipart/form-data">

                        {/* Kiosk waiver member details */}
                        {(waiverMembers) &&
                            <>
                                {/* waiver count and add button */}
                                <Row className='mt-4'>
                                    <Col sm={12}>
                                        <div
                                            className='d-flex justify-content-between align-items-center flex-wrap'>
                                            <div className='guardian-title'>
                                                <span  className='fw-bold' style={{
                                                    backgroundColor: '#dedede', padding: '3px 12px',
                                                    borderRadius: '15px', color: '#df1e1e'}}>
                                                    Member: 1. PARENT/GUARDIAN/SIGNING PERSON INFORMATION (MUST BE 18+ YEAR OLD TO SIGN)
                                                </span>
                                            </div>

                                            {/*<button type='button' className='btn btn-success btn-md me-1'
                                                    onClick={(e) => handleAddWaiverMember(e)}>
                                                <PlusCircle/> Member
                                            </button>*/}
                                        </div>
                                    </Col>
                                </Row>

                                {/* Signing person details */}
                                {waiverMembers[0] && (() => {
                                    const sign_index = 0;
                                    const sign_member = waiverMembers[sign_index] || {};
                                    const month_days = setMonthDays(sign_member.dob_month)
                                    const sign_error = (errors.members_errors && errors.members_errors[sign_index]) ? errors.members_errors[sign_index] : {};
                                    console.log('sign_member: ', sign_member)
                                    return (
                                        <div className='waiver-mem-detail-sec mt-1'>
                                            {/* style={{borderBottom: '1px solid #d9d9d9'}} */}

                                            {/* Member name and date of birth*/}
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group controlId={`first_name_${sign_index}`}>
                                                        <Form.Label className='req-label'>First
                                                            Name</Form.Label>
                                                        <Form.Control size='sm' type="text"
                                                                      name='first_name'
                                                                      className={(sign_error.first_name) && 'is-invalid'}
                                                                      value={sign_member.first_name}
                                                                      onChange={(e) => handleMemberChange(e, sign_index)}
                                                        />
                                                        <div
                                                            className="invalid-feedback">{sign_error.first_name}</div>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4}>
                                                    <Form.Group controlId={`last_name_${sign_index}`}>
                                                        <Form.Label className='req-label'>Last
                                                            Name</Form.Label>
                                                        <Form.Control size='sm' type="text" name='last_name'
                                                                      className={(sign_error.last_name) && 'is-invalid'}
                                                                      value={sign_member.last_name}
                                                                      onChange={(e) => handleMemberChange(e, sign_index)}
                                                        />
                                                        <div
                                                            className="invalid-feedback">{sign_error.last_name}</div>
                                                    </Form.Group>
                                                </Col>

                                                {(waiverSettings.phone == 1) &&
                                                    <Col md={4}>
                                                        <Form.Group controlId={`contact_no_${sign_index}`}>
                                                            <Form.Label className='req-label'>Contact No</Form.Label>
                                                            <Form.Control size='sm' type="text"
                                                                          name='contact_no'
                                                                          className={(sign_error.contact_no) && 'is-invalid'}
                                                                          value={sign_member.contact_no}
                                                                          onChange={(e) => handleMemberChange(e, sign_index)}
                                                            />
                                                            <div
                                                                className="invalid-feedback">{sign_error.contact_no}</div>
                                                        </Form.Group>
                                                    </Col>
                                                }


                                                {/* Date of Birth */}
                                                {(waiverSettings.year || waiverSettings.month || waiverSettings.day) &&
                                                    <Col md={3} className='mt-3'>
                                                        <div className='mb-2 d-inline-flex border-bottom'>
                                                            Date of birth <div className='req-label'></div>
                                                        </div>

                                                        <div className='d-flex gap-1 is-invalid'>
                                                            {(waiverSettings.year) &&
                                                                <div>
                                                                    <Form.Group
                                                                        controlId={`dob_year_${sign_index}`}>
                                                                        <Form.Label>Year</Form.Label>
                                                                        <Form.Select size='sm' name='dob_year'
                                                                                     className={(sign_error.dob_year) && 'is-invalid'}
                                                                                     value={sign_member.dob_year}
                                                                                     onChange={(e) => handleMemberChange(e, sign_index)}>

                                                                            <option value=''></option>
                                                                            {years.map((year, index) => {
                                                                                return (
                                                                                    <option key={index} value={year}>{year}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Form.Select>
                                                                        <div className="invalid-feedback">{sign_error.dob_year}</div>
                                                                    </Form.Group>
                                                                </div>
                                                            }
                                                            {(waiverSettings.month == 1) &&
                                                                <div>
                                                                    <Form.Group
                                                                        controlId={`dob_month_${sign_index}`}>
                                                                        <Form.Label>Month</Form.Label>
                                                                        <Form.Select size='sm' name='dob_month'
                                                                                     className={(sign_error.dob_month) && 'is-invalid'}
                                                                                     value={sign_member.dob_month}
                                                                                     onChange={(e) => handleMemberChange(e, sign_index)}
                                                                        >
                                                                            <option value=''></option>
                                                                            {months.map((month, index) => {
                                                                                return (
                                                                                    <option key={index}
                                                                                            value={month}>{month}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Form.Select>
                                                                        <div
                                                                            className="invalid-feedback">{sign_error.dob_month}</div>
                                                                    </Form.Group>
                                                                </div>
                                                            }
                                                            {(waiverSettings.day == 1) &&
                                                                <div>
                                                                    <Form.Group
                                                                        controlId={`dob_day_${sign_index}`}>
                                                                        <Form.Label>Day</Form.Label>
                                                                        <Form.Select size='sm' name='dob_day'
                                                                                     className={(sign_error.dob_day) && 'is-invalid'}
                                                                                     value={sign_member.dob_day}
                                                                                     onChange={(e) => handleMemberChange(e, sign_index)}
                                                                        >
                                                                            <option value=''></option>
                                                                            {month_days.map((day, index) => {
                                                                                return (
                                                                                    <option key={index} value={day}>{day}</option>
                                                                                )
                                                                            })
                                                                            }
                                                                        </Form.Select>
                                                                        <div className="invalid-feedback">{sign_error.dob_day}</div>
                                                                    </Form.Group>
                                                                </div>
                                                            }
                                                        </div>

                                                    </Col>
                                                }


                                                <Col md={9} className='mt-3'>
                                                    {/* Member address section */}
                                                    <div className='flex-1'>
                                                        <Row>
                                                            {(waiverSettings['country'] == 1) &&
                                                                <Col md={3}>
                                                                    <Form.Group
                                                                        controlId={`country_id_${sign_index}`}>
                                                                        <Form.Label
                                                                            className='req-label'>Country</Form.Label>
                                                                        <Select name='country_id'
                                                                                styles={selectStyles}
                                                                                className={(sign_error.country_id) && 'is-invalid select-error'}
                                                                                options={countries}
                                                                                value={(!sign_member.address_same_as) ? sign_member.selected_country : {}}
                                                                                onChange={(e) => handleMemberChange(e, sign_index, 'country_id')}
                                                                        />
                                                                        <div
                                                                            className="invalid-feedback">{sign_error.country_id}</div>
                                                                    </Form.Group>

                                                                </Col>
                                                            }

                                                            {(waiverSettings['state'] == 1 || waiverSettings['province'] == 1) &&
                                                                <Col md={3}>
                                                                    <Form.Group
                                                                        controlId={`state_id_${sign_index}`}>
                                                                        <Form.Label
                                                                            className='req-label'>State/Province</Form.Label>
                                                                        <Select name='state_id'
                                                                                styles={selectStyles}
                                                                                className={(sign_error.state_id) && 'is-invalid select-error'}
                                                                                options={sign_member.states}
                                                                                value={(!sign_member.address_same_as) ? sign_member.selected_state : {}}
                                                                                onChange={(e) => handleMemberChange(e, sign_index, 'state_id')}
                                                                        />
                                                                        <div
                                                                            className="invalid-feedback">{sign_error.state_id}</div>
                                                                    </Form.Group>
                                                                </Col>
                                                            }

                                                            {(waiverSettings['city'] == 1) &&
                                                                <Col md={3}>
                                                                    <Form.Group
                                                                        controlId={`city_${sign_index}`}>
                                                                        <Form.Label
                                                                            className='req-label'>City</Form.Label>
                                                                        <Form.Control size='sm' type="text"
                                                                                      name='city'
                                                                                      className={(sign_error.city) && 'is-invalid'}
                                                                                      value={(!sign_member.address_same_as) ? sign_member.city : ''}
                                                                                      onChange={(e) => handleMemberChange(e, sign_index)}
                                                                                      disabled={!!(sign_member.address_same_as)}/>
                                                                        <div
                                                                            className="invalid-feedback">{sign_error.city}</div>
                                                                    </Form.Group>
                                                                </Col>
                                                            }

                                                            {(waiverSettings['postal-code'] == 1) &&
                                                                <Col md={3}>
                                                                    <Form.Group
                                                                        controlId={`postal_code_${sign_index}`}>
                                                                        <Form.Label className='req-label'>Postal
                                                                            Code</Form.Label>
                                                                        <Form.Control size='sm' type="text" name='postal_code'
                                                                                      className={(sign_error.postal_code) && 'is-invalid'}
                                                                                      value={sign_member.postal_code}
                                                                                      onChange={(e) => handleMemberChange(e, sign_index)}
                                                                                      disabled={!!(sign_member.address_same_as)}/>
                                                                        <div
                                                                            className="invalid-feedback">{sign_error.postal_code}</div>
                                                                    </Form.Group>
                                                                </Col>
                                                            }

                                                            {(waiverSettings['street-address'] == 1) &&
                                                                <>
                                                                    <Col md={6} className='mt-2'>
                                                                        <Form.Group
                                                                            controlId={`address2_${sign_index}`}>
                                                                            <Form.Label>Address 1 <small
                                                                                className='text-muted'>
                                                                                (Street address or P.O
                                                                                Box)</small></Form.Label>
                                                                            <Form.Control size='sm' type="text" name='address1'
                                                                                          className={(sign_error.address1) && 'is-invalid'}
                                                                                          value={sign_member.address1}
                                                                                          onChange={(e) => handleMemberChange(e, sign_index)}
                                                                                          disabled={!!(sign_member.address_same_as)}/>
                                                                            <div
                                                                                className="invalid-feedback">{sign_error.address1}</div>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={6} className='mt-2'>
                                                                        <Form.Group
                                                                            controlId={`address2_${sign_index}`}>
                                                                            <Form.Label>Address 2 <small
                                                                                className='text-muted'>(Apt.,
                                                                                Unit, Building,
                                                                                Suite)</small></Form.Label>
                                                                            <Form.Control size='sm'
                                                                                          type="text"
                                                                                          name='address2'
                                                                                          className={(sign_error.address2) && 'is-invalid'}
                                                                                          value={sign_member.address2}
                                                                                          onChange={(e) => handleMemberChange(e, sign_index)}
                                                                                          disabled={!!(sign_member.address_same_as)}/>
                                                                            <div
                                                                                className="invalid-feedback">{sign_error.address2}</div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                            }

                                                        </Row>

                                                    </div>
                                                </Col>

                                            </Row>

                                        </div>
                                    );
                                    })()
                                }


                                {/* waiver members table title */}
                                <Row className='mt-4'>
                                    <Col md={12}>
                                        <div className='d-flex justify-content-between'>
                                            <div className='' style={{
                                                padding: '3px 12px', borderRadius: '15px',
                                                fontSize: '16px', backgroundColor: '#dedede'
                                            }}>
                                                Waiver Members
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={12}></Col>
                                </Row>

                                {/* waiver members details Table */}
                                <Table bordered hover size='sm' className='table-xs mt-2'>
                                    <thead>
                                    <tr className='bg-light'>
                                        <th>#</th>
                                        <th>First Name</th>
                                        <th>Date of Birth</th>
                                        <th>-</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {waiverMembers.map((waiverMember, index) => {

                                            const m_error = (errors.members_errors && errors.members_errors[index]) ? errors.members_errors[index] : {}
                                            const member_error = (m_error && Object.entries(m_error).length > 0);

                                            if (index !== 0 && waiverMember.status != 5 && (waiverMember.first_name != '' || waiverMember.first_name != '')) {

                                                let dob = '-';
                                                if (waiverMember.dob) {
                                                    dob = moment(waiverMember.dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
                                                }else if(waiverMember.dob_year && waiverMember.dob_month && waiverMember.dob_day) {
                                                    dob = waiverMember.dob_year + '-' + waiverMember.dob_month + '-' + waiverMember.dob_day
                                                    dob = moment(dob, 'YYYY-MMMM-D').format('DD-MM-YYYY');
                                                }

                                                return (
                                                    <tr>
                                                        <td style={{width: '47px'}}>{index + 1}</td>
                                                        <td>{waiverMember.first_name} {waiverMember.last_name}</td>
                                                        <td>
                                                            {dob}
                                                            {(member_error) &&
                                                                <span className={`float-end text-danger is-invalid`}>Member data error</span>
                                                            }
                                                        </td>
                                                        <td style={{width: '73px'}}>
                                                            <button type='button'
                                                                    className='btn btn-success btn-xs me-1'
                                                                    onClick={(e) => handleEditMember(e, index)}>
                                                                <Pencil/>
                                                            </button>

                                                            <button type='button' className='btn btn-danger btn-xs me-1'
                                                                    onClick={() => setMemberRemove(index)}>
                                                                <DashLg/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    )}
                                    </tbody>
                                </Table>

                                {/* Selected Member Details */}
                                {(waiverMembers && selectedMemberIndex) &&
                                    (() => {
                                        m_index = selectedMemberIndex;
                                        s_member = waiverMembers[m_index] || {};
                                        const month_days = setMonthDays(s_member.dob_month)
                                        m_error = (errors.members_errors && errors.members_errors[m_index]) ? errors.members_errors[m_index] : {};
                                        console.log('m_error:: ', m_error);

                                        return (
                                            <div className='waiver-mem-detail-sec mt-1'>
                                                    {/* style={{borderBottom: '1px solid #d9d9d9'}} */}

                                                    {/* Member name and date of birth*/}
                                                    <Row>
                                                        <Col md={4}>
                                                            <Form.Group controlId={`first_name_${m_index}`}>
                                                                <Form.Label className='req-label'>First
                                                                    Name</Form.Label>
                                                                <Form.Control size='sm' type="text" name='first_name'
                                                                              className={(m_error.first_name) && 'is-invalid'}
                                                                              value={s_member.first_name}
                                                                              onChange={(e) => handleMemberChange(e, m_index)}
                                                                />
                                                                <div
                                                                    className="invalid-feedback">{m_error.first_name}</div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col md={4}>
                                                            <Form.Group controlId={`last_name_${m_index}`}>
                                                                <Form.Label className='req-label'>Last
                                                                    Name</Form.Label>
                                                                <Form.Control size='sm' type="text" name='last_name'
                                                                              className={(m_error.last_name) && 'is-invalid'}
                                                                              value={s_member.last_name}
                                                                              onChange={(e) => handleMemberChange(e, m_index)}
                                                                />
                                                                <div
                                                                    className="invalid-feedback">{m_error.last_name}</div>
                                                            </Form.Group>
                                                        </Col>

                                                        {(waiverSettings.phone == 1) &&
                                                            <Col md={4}>
                                                                <Form.Group
                                                                    controlId={`contact_no_${m_index}`}>
                                                                    <Form.Label className='req-label'>Contact
                                                                        No</Form.Label>
                                                                    <Form.Control size='sm' type="text" name='contact_no'
                                                                                  className={(m_error.contact_no) && 'is-invalid'}
                                                                                  value={s_member.contact_no}
                                                                                  onChange={(e) => handleMemberChange(e, m_index)}
                                                                    />
                                                                    <div
                                                                        className="invalid-feedback">{m_error.contact_no}</div>
                                                                </Form.Group>
                                                            </Col>
                                                        }


                                                        {/* Date of Birth */}
                                                        {(waiverSettings.year == 1 || waiverSettings.month == 1 || waiverSettings.day == 1) &&
                                                            <Col md={3} className='mt-3'>
                                                                <div
                                                                    className='mb-2 d-inline-flex border-bottom'>
                                                                    Date of birth
                                                                    <div className='req-label'></div>
                                                                </div>

                                                                <div className='d-flex gap-1 is-invalid'>
                                                                    {(waiverSettings.year == 1) &&
                                                                        <div>
                                                                            <Form.Group
                                                                                controlId={`dob_year_${m_index}`}>
                                                                                <Form.Label>Year</Form.Label>
                                                                                <Form.Select size='sm' name='dob_year'
                                                                                             className={(m_error.dob_year) && 'is-invalid'}
                                                                                             value={s_member.dob_year}
                                                                                             onChange={(e) => handleMemberChange(e, m_index)}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    {years.map((year, index) => {
                                                                                        return (
                                                                                            <option key={index}
                                                                                                    value={year}>{year}</option>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </Form.Select>
                                                                                <div
                                                                                    className="invalid-feedback">{m_error.dob_year}</div>
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {(waiverSettings.month == 1) &&
                                                                        <div>
                                                                            <Form.Group
                                                                                controlId={`dob_month_${selectedMember.index}`}>
                                                                                <Form.Label>Month</Form.Label>
                                                                                <Form.Select size='sm'
                                                                                             name='dob_month'
                                                                                             className={(m_error.dob_month) && 'is-invalid'}
                                                                                             value={s_member.dob_month}
                                                                                             onChange={(e) => handleMemberChange(e, m_index)}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    {months.map((month, index) => {
                                                                                        return (
                                                                                            <option key={index} value={month}>{month}</option>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </Form.Select>
                                                                                <div
                                                                                    className="invalid-feedback">{m_error.dob_month}</div>
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                    {(waiverSettings.day == 1) &&
                                                                        <div>
                                                                            <Form.Group
                                                                                controlId={`dob_day_${m_index}`}>
                                                                                <Form.Label>Day</Form.Label>
                                                                                <Form.Select size='sm' name='dob_day'
                                                                                             className={(m_error.dob_day) && 'is-invalid'}
                                                                                             value={s_member.dob_day}
                                                                                             onChange={(e) => handleMemberChange(e, m_index)}
                                                                                >
                                                                                    <option value=''></option>
                                                                                    {month_days.map((day, index) => {
                                                                                        return (
                                                                                            <option key={index} value={day}>{day}</option>
                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </Form.Select>
                                                                                <div
                                                                                    className="invalid-feedback">{m_error.dob_day}</div>
                                                                            </Form.Group>
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </Col>
                                                        }


                                                        <Col md={9} className='mt-3'>
                                                            {/* Member address section */}
                                                            <div className='flex-1'>
                                                                <Row>

                                                                    <Col md={8}>
                                                                        <Form.Group className=""
                                                                                    controlId={`address_same_as_${m_index}`}>
                                                                            <Form.Check type="checkbox"
                                                                                        className="custom-checkbox"
                                                                                        name='address_same_as'
                                                                                        label="Address same as guardian"
                                                                                        defaultChecked={s_member.address_same_as}
                                                                                        onChange={(e) => handleMemberChange(e, m_index)}/>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </Row>

                                                                {(!s_member.address_same_as) &&
                                                                    <Row>
                                                                        {(waiverSettings['country'] == 1) &&
                                                                            <Col md={3}>
                                                                                <Form.Group
                                                                                    controlId={`country_id_${m_index}`}>
                                                                                    <Form.Label
                                                                                        className='req-label'>Country</Form.Label>
                                                                                    <Select name='country_id'
                                                                                            styles={selectStyles}
                                                                                            className={(m_error.country_id) && 'is-invalid select-error'}
                                                                                            options={countries}
                                                                                            value={(!s_member.address_same_as) ? s_member.selected_country : {}}
                                                                                            onChange={(e) => handleMemberChange(e, m_index, 'country_id')}
                                                                                            isDisabled={!!(s_member.address_same_as)}/>
                                                                                    <div
                                                                                        className="invalid-feedback">{m_error.country_id}</div>
                                                                                </Form.Group>

                                                                            </Col>
                                                                        }

                                                                        {(waiverSettings['state'] == 1 || waiverSettings['province'] == 1) &&
                                                                            <Col md={3}>
                                                                                <Form.Group
                                                                                    controlId={`state_id_${m_index}`}>
                                                                                    <Form.Label
                                                                                        className='req-label'>State/Province</Form.Label>
                                                                                    <Select name='state_id'
                                                                                            styles={selectStyles}
                                                                                            className={(m_error.state_id) && 'is-invalid select-error'}
                                                                                            options={s_member.states}
                                                                                            value={(!s_member.address_same_as) ? s_member.selected_state : {}}
                                                                                            onChange={(e) => handleMemberChange(e, m_index, 'state_id')}
                                                                                            isDisabled={!!(s_member.address_same_as)}
                                                                                    />
                                                                                    <div
                                                                                        className="invalid-feedback">{m_error.state_id}</div>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        }

                                                                        {(waiverSettings['city'] == 1) &&
                                                                            <Col md={3}>
                                                                                <Form.Group
                                                                                    controlId={`city_${m_index}`}>
                                                                                    <Form.Label
                                                                                        className='req-label'>City</Form.Label>
                                                                                    <Form.Control size='sm'
                                                                                                  type="text"
                                                                                                  name='city'
                                                                                                  className={(m_error.city) && 'is-invalid'}
                                                                                                  value={(!s_member.address_same_as) ? s_member.city : ''}
                                                                                                  onChange={(e) => handleMemberChange(e, m_index)}
                                                                                                  disabled={!!(s_member.address_same_as)}/>
                                                                                    <div
                                                                                        className="invalid-feedback">{m_error.city}</div>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        }

                                                                        {(waiverSettings['postal-code'] == 1) &&
                                                                            <Col md={3}>
                                                                                <Form.Group
                                                                                    controlId={`postal_code_${m_index}`}>
                                                                                    <Form.Label
                                                                                        className='req-label'>Postal
                                                                                        Code</Form.Label>
                                                                                    <Form.Control size='sm'
                                                                                                  type="text"
                                                                                                  name='postal_code'
                                                                                                  className={(m_error.postal_code) && 'is-invalid'}
                                                                                                  value={s_member.postal_code}
                                                                                                  onChange={(e) => handleMemberChange(e, m_index)}
                                                                                                  disabled={!!(s_member.address_same_as)}/>
                                                                                    <div
                                                                                        className="invalid-feedback">{m_error.postal_code}</div>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        }

                                                                        {(waiverSettings['street-address'] == 1) &&
                                                                            <>
                                                                                <Col md={6} className='mt-2'>
                                                                                    <Form.Group
                                                                                        controlId={`address2_${m_index}`}>
                                                                                        <Form.Label>Address 1 <small className='text-muted'>(Street address or P.O Box)</small></Form.Label>
                                                                                        <Form.Control size='sm' type="text" name='address1'
                                                                                                      className={(m_error.address1) && 'is-invalid'}
                                                                                                      value={s_member.address1}
                                                                                                      onChange={(e) => handleMemberChange(e, m_index)}
                                                                                                      disabled={!!(s_member.address_same_as)}/>
                                                                                        <div className="invalid-feedback">{m_error.address1}</div>
                                                                                    </Form.Group>
                                                                                </Col>

                                                                                <Col md={6} className='mt-2'>
                                                                                    <Form.Group controlId={`address2_${m_index}`}>
                                                                                        <Form.Label>Address 2 <small className='text-muted'>(Apt., Unit, Building, Suite)</small></Form.Label>
                                                                                        <Form.Control size='sm' type="text" name='address2'
                                                                                                      className={(m_error.address2) && 'is-invalid'}
                                                                                                      value={s_member.address2}
                                                                                                      onChange={(e) => handleMemberChange(e, m_index)}
                                                                                                      disabled={!!(s_member.address_same_as)}/>
                                                                                        <div className="invalid-feedback">{m_error.address2}</div>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </>
                                                                        }
                                                                    </Row>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <div className='d-flex justify-content-end'>
                                                        <button type='button'
                                                                className='btn btn-success mt-2 btn-md me-2'
                                                                onClick={(e) => handleValidateWaiverMember(e)}>
                                                            Save & Close
                                                        </button>

                                                        <button type='button'
                                                                className='btn btn-warning mt-2 btn-md'
                                                                onClick={(e) => handleCancelMember(e)}>
                                                            Cancel Edit
                                                        </button>
                                                    </div>
                                                </div>
                                        );
                                    })()
                                }

                                {/* Member add button */}
                                {(waiverMembers.length > 0 && !selectedMemberIndex) &&
                                    <div className='d-flex justify-content-center mt-3'>
                                        <button type='button' className='btn btn-success me-1 btn-lg'
                                                onClick={(e) => {
                                                    setSelectedMemberIndex(null)
                                                    handleAddWaiverMember(e)
                                                }}>
                                            <PlusCircle/> Add Member
                                        </button>
                                    </div>
                                }
                                {/* End of add member section */}

                                {/* Terms and conditions */}
                                <Row className='mt-3'>
                                    <Col sm={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Terms and Conditions</Form.Label>
                                            <Form.Control size='sm' rows={10} as="textarea"
                                                          defaultValue={waiverSettings['waiver-text']}
                                                          readOnly={true} disabled={true}/>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className="mb-3" controlId="subscribe_newsletter">
                                            <Form.Check type="checkbox" size='sm'
                                                        className={`custom-checkbox ${(errors.subscribe_newsletter) && 'is-invalid'}`}
                                                        label="I would like to subscribe to the monthly newsletter to be emailed about upcoming events and promotions!"
                                                        onChange={(e) => handleChange('subscribe_newsletter', e.target.checked)}
                                                        required/>
                                            <div
                                                className="invalid-feedback">{errors.subscribe_newsletter}</div>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className="mb-3"
                                                    controlId="accept_terms_and_conditions">
                                            <Form.Check type="checkbox" size='sm'
                                                        className={`custom-checkbox ${(errors.accept_terms_and_conditions) && 'is-invalid'}`}
                                                        label="I AGREE TO THE TERMS AND DECLARE THAT ALL PERSONAL INFORMATION ARE CORRECT"
                                                        onChange={(e) => handleChange('accept_terms_and_conditions', e.target.checked)}
                                                        required/>
                                            <div
                                                className="invalid-feedback">{errors.accept_terms_and_conditions}</div>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Signatures */}
                                <Row className='mt-3'>
                                    {waiverMembers.map((member, index) => {
                                        if (index !== 0) {
                                           if(!member.dob_year) return null
                                        }

                                        const calculatedAge = calculateAge(member.dob_year, member.dob_month ?? 1, member.dob_day ?? 1)
                                        console.log('calculatedAge: ', calculatedAge)
                                        if ((calculatedAge >= 18 && member.first_name != '') || index == 0) {
                                            const m_error = (errors.members_errors && errors.members_errors[index]) ? errors.members_errors[index] : {}
                                            // console.log(m_error)
                                            return (
                                                <Col key={index} md={3} className='mt-3'>
                                                    <div>
                                                        <div className="mb-1">
                                                            <Form.Check inline type='radio'
                                                                        id={`image-signature-${index}`}
                                                                        name={`signature_type_${index}`}
                                                                        defaultValue='image'
                                                                        label="Draw Sig"
                                                                        defaultChecked={(member.signature_type == 'image') ? true : false}
                                                                        onChange={(e) => handleMemberChange(e, index)}/>
                                                            <Form.Check inline type='radio'
                                                                        id={`text-signature-${index}`}
                                                                        name={`signature_type_${index}`}
                                                                        defaultValue='text'
                                                                        label="Text Sig"
                                                                        defaultChecked={(member.signature_type == 'text') ? true : false}
                                                                        onChange={(e) => handleMemberChange(e, index)}/>
                                                        </div>
                                                    </div>
                                                    <div className='is-invalid'>
                                                        {(member.signature_type == 'image') &&
                                                            <>
                                                                <Form.Label>Draw Signature
                                                                    ({member.first_name + ' ' + member.last_name})</Form.Label>
                                                                <div>
                                                                    <div className='signature-container'>
                                                                        <SignatureCanvas penColor='green'
                                                                                         canvasProps={{
                                                                                             id: `draw_signature_${index}`,
                                                                                             width: 220,
                                                                                             height: 120,
                                                                                             className: 'sigCanvas'
                                                                                         }}/>
                                                                    </div>
                                                                </div>
                                                                <Button size='sm' type="button"
                                                                        variant="secondary" className='mt-2'
                                                                        onClick={(e) => handleClearSignature(index)}>Clear</Button>
                                                            </>
                                                        }

                                                        {(member.signature_type == 'text') &&
                                                            <Form.Group className="mb-1"
                                                                        controlId="text_signature">
                                                                <Form.Label>Text Signature
                                                                    ({member.first_name + ' ' + member.last_name})</Form.Label>
                                                                <Form.Control size='sm' type="text"
                                                                              name='text_signature'
                                                                              className={(errors.text_signature) && 'is-invalid'}
                                                                              defaultValue={member.text_signature}
                                                                              onChange={(e) => handleMemberChange(e, index)}
                                                                              required/>
                                                            </Form.Group>
                                                        }
                                                    </div>

                                                    {(m_error.signature) &&
                                                        <div
                                                            className="text-danger invalid-feedback">{m_error.signature}</div>
                                                    }
                                                </Col>
                                            )
                                        }
                                    })}
                                </Row>


                                {/*<Button size='md' type="button" variant="danger" className='float-end mt-2' onClick={() => {
                                   handleCheckSignature()
                                }}>Check Signature</Button>*/}
                            </>
                        }

                        {/*<Button variant="secondary">Close</Button>*/}
                        <div className='hr-separator mt-3'></div>
                        <div className='text-end mt-2'>

                            <Button size='md' type="button" variant="primary" className='me-2'
                                    onClick={() => handleValidateWaiverForm()}>
                                Submit Waiver
                            </Button>

                            {/* Cancel button */}
                            <Button size='md' type="button" variant="warning"
                                    onClick={() => handleCancelWaiver()}>Cancel</Button>
                        </div>
                    </Form>
                }

            </div>

            {/* waiver Modals */}
            <Modal show={waiverModal} size='lg' animation={false}>
                <Modal.Header>
                    <Modal.Title>Account Creation</Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => setWaiverModal(false)}></button>
                </Modal.Header>

                <Modal.Body>

                    <div className="d-flex justify-content-center">
                        <div className="waiver-register">

                            <div className='fw-bold text-center fs-26x'>Add password to create an account!</div>

                            <div className='text-muted fs-17x fw-bold mb-2 text-center' style={{margin: "0 50px"}}>
                                By creating an account you can view and update your waiver.
                            </div>

                            <div style={{textAlign: 'left'}}>
                                <div className="form-group mt-2">
                                    <label className=''>Enter Password</label>
                                    <input type="password" className={`form-control ${(errors.password) && 'is-invalid'}`}
                                           placeholder="---"
                                           defaultValue={waiver.password}
                                           onChange={(e) => handleChange('password', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.password}</div>
                                </div>

                                <div className="form-group mt-2">
                                    <label className=''>Re-enter Password</label>
                                    <input type="password"
                                           className={`form-control ${(errors.password_confirmation) && 'is-invalid'}`}
                                           placeholder="---"
                                           defaultValue={waiver.password_confirmation}
                                           onChange={(e) => handleChange('password_confirmation', e.target.value)}/>
                                    <div className="invalid-feedback">{errors.password_confirmation}</div>
                                </div>

                                <div className='text-muted fs-16x mb-2'>
                                    Password must be at least 6 characters, minimum one letter and one digit is
                                    required.
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='border-top text-end pt-2'>
                        <Button size='md' variant="warning" className='me-2'
                                onClick={(e) => handleFormSubmit(e, 'guest_sign')}>
                            SUBMIT WAIVER AS A GUEST</Button>

                        <Button size='md' variant="success" className='me-2'
                                onClick={(e) => handleFormSubmit(e, 'account_sign')}>
                            CREATE ACCOUNT & SUBMIT WAIVER</Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Alert modal */}
            <Modal show={waiverConfirmationAlert} size='md' animation={true}>
                <Modal.Body>
                    <div className='alert-icon'><ExclamationCircle/></div>
                    <div className='alert-title'>ARE YOU SURE!</div>
                    <div className='fs-19x mb-3 mt-2'>You have added all children and adults to this waiver?</div>

                    <div className='border-top text-end pt-2'>
                        <button type="button" className="btn btn-warning me-2" onClick={() => {setWaiverConfirmationAlert(false)}}>
                            LET ME ADD MORE
                        </button>

                        <button type="button" className="btn btn-success" onClick={(e) =>
                            (customerId && customerId !== '' && customerId !== ' ') ? handleFormSubmit(e, 'add_submit')
                                :
                                (
                                    setWaiverModal(true),
                                    setWaiverConfirmationAlert(false)
                                )}>
                            YES SUBMIT
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* waiver member remove alert modal */}
            <Modal show={(memberRemove !== null)} size='md' animation={true}>
                <Modal.Body>
                    <div className='alert-icon'><ExclamationCircle/></div>
                    <div className='alert-title'>ARE YOU SURE!</div>
                    <div className='fs-19x mb-3 mt-2 text-center'>Want to remove waiver member?</div>

                    <div className='border-top text-end pt-2'>
                        <button type="button" className="btn btn-secondary me-2" onClick={() => {
                            setMemberRemove(null)
                        }}>NO
                        </button>

                        <button type="button" className="btn btn-danger" onClick={(e) => {
                            handleRemoveWaiverMember(memberRemove)
                        }}>YES REMOVE
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}