import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import logo_image from "../../images/playtopia-logo-1.png";
import {Alert} from "react-bootstrap";
import URL from "../_services/routes";

export default function Waiver() {
    const [global, dispatch] = useContext(GlobalContext)
    const [response, setResponse] = useState(null)

    const res = URL.urlQueryParams('response');
    useEffect(() => {

        if (res) {
            const msg = 'You have successfully signed the waiver!';
            setResponse(msg)
            dispatch({type: "SHOW-SUCCESS", payload: msg})
        }

    }, []);

    const handleUpdateWaiver = () => {
        dispatch({type: 'RETURNING-URL', payload: '/waiver-sign'})
        if (global.user && global.user.id && global.token) {
            window.location.assign('/waiver-sign?waiver=update')
        } else {
            window.location.assign('/login?rd=waiver-sign&waiver=update')
        }
    }

    return (
        <div className='kiosk-bg-img d-flex justify-content-center'>
            <div className='text-center' style={{marginTop: '40px'}}>

                {(res && response) &&
                    <div className='mt-3'>
                        <Alert variant='success'>
                            <div className='fs-24x fw-bold'>{response}</div>
                        </Alert>
                    </div>
                }

                <div style={{width: '253px'}} className='mb-4 m-auto'>
                    <img style={{width: '100%'}} src={global.pcl.dark_logo || logo_image} alt='Playtopia'/>
                </div>

                <a className='btn btn-primary btn-lg me-3 mb-3' style={{minWidth: '276px'}}
                   href={`/waiver-sign?waiver=new`}>SIGN A NEW WAIVER</a>

                <button className='btn btn-primary btn-lg mb-3'  style={{minWidth: '276px'}}
                        onClick={()=>handleUpdateWaiver()}>UPDATE PREVIOUS WAIVER</button>
            </div>
        </div>
    )
}