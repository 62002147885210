import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../_services/global-context";
import Api from "../_services/api_service";
import {Col, Row, Table} from "react-bootstrap";
import {ArrowUpRightCircleFill, CurrencyDollar, Gear} from "react-bootstrap-icons";
import moment from "moment";
export default function Orders() {
    const [global, dispatch] = useContext(GlobalContext);
    const [orders, setOrders] = useState([]);

    useEffect(() => {

        handleLoadDetails()

    }, [])

    const handleLoadDetails = () => {
        const qryParams = '?pcl_id=' + global.pcl.id;
        Api.get(`client/orders` + qryParams, (res) => {
            const data = res.data.data
            setOrders(data)
        })
    }

    const handleLoadOrder = (order_id) => {
        dispatch({type: "ORDER-ID", payload: order_id})
        dispatch({type: "ORDER-TIME", payload: moment().format('YYYY-MM-DD HH:mm')})

        // dispatch({type: "SHOW-SUCCESS", payload: res.data.message})
        window.location.assign('/cart')
    }

    return (
        <div className=''>
            <div className='text-dark-light fs-22x fw-bold mb-1'>All Orders</div>
            <div className='d-flex align-items-center data-list mb-1 gap-3'>
                <div className='fw-bold' style={{width: '70px'}}>#</div>
                <div className='fw-bold' style={{width: '170px'}}>Order No</div>
                <div className='fw-bold flex-1'>Details</div>
                <div className='fw-bold flex-1'>$ Status</div>
                <div className='fw-bold pe-3'>Action</div>
            </div>
            {orders.map((order, index) => {
                let p_class = 'bg-danger text-light'
                let payment_status = 'No Payment'
                if (order.payment_status == 1) {
                    p_class = 'bg-warning'
                    payment_status = 'Partial Paid'
                } else if (order.payment_status == 2) {
                    p_class = 'bg-light-green text-light'
                    payment_status = 'Fully Paid'
                }
                return (
                    <div className='d-flex align-items-center data-list mb-1 gap-3'>
                        <div style={{width: '70px'}}>{index + 1}</div>
                        <div style={{width: '170px'}}>{order.order_number}</div>
                        <div className='flex-1'>{order.item_names}</div>
                        <div className='flex-1'><span className={`${p_class} list-status`}>{payment_status}</span></div>
                        <div className='d-flex gap-2'>
                            {(order.total_enable_rsvps >= 1) &&
                                <a href={`/rsvp?order-id=` + order.id} className='btn btn-sm btn-outline-primary'>
                                    <Gear/> Rsvp
                                </a>
                            }

                            <button onClick={() => handleLoadOrder(order.id)}
                                    className='btn btn-sm btn-outline-success'>
                                <ArrowUpRightCircleFill className='fs-22x text-light-green cursor-pointer'/>
                            </button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}