import {useContext, useState} from "react";
import {Alert} from "react-bootstrap";
import {GlobalContext} from "./global-context";
import {EmojiFrown, EmojiSmile} from "react-bootstrap-icons";

export default function GlobalAlert() {
    const [global, dispatch] = useContext(GlobalContext);
    if (global.alert == undefined || global.alert.msg == undefined || global.alert.msg == "") return null;

    const handleAlert = () => {
        dispatch({type: "SHOW-SUCCESS", payload: ""})
        dispatch({type: "SHOW-WARNING", payload: ""})
        dispatch({type: "SHOW-ERROR", payload: ""})
    }

    const aType = global.alert.type;
    const title = (aType === 'success') ? '' : (aType === 'error') ? 'Error' : 'Alert'

    const alertTime = (aType === 'success') ? 2000 : 4000;
    setTimeout(() => {
        handleAlert()
    }, alertTime);


    return (
        <div className="alert-wrapper">
            <Alert variant={global.alert.type} className='global-alert' onClose={() => handleAlert()} dismissible>
                {/*<Alert.Heading>{title} {(aType === 'success')?
                    <EmojiSmile style={{marginTop: '-6px'}}/> :
                    <EmojiFrown style={{marginTop: '-6px'}}/>}
                </Alert.Heading>*/}
                <p className='m-0'>{global.alert.msg}</p>
                <br/>
            </Alert>
        </div>
    );
}
