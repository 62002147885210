import React from "react";
import error_image from "../images/404-error.png"

export default function NotFound() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-12 text-center">
                <img src={error_image}/>
                {/*<span className="display-1 d-block">404</span>*/}
                <div className="mb-4 mt-4 lead">The page you are looking for was not found.</div>
                <a href="/" className="btn btn-link">Back to Home</a>
            </div>
        </div>
    )
}